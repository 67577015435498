<template lang="pug">
layoutContainer
  .enter-container
    .tab-bar
      .tab(
        v-for="(item, index) in tabBarList"
        :key="index"
        @click="selectType = item.value;subTabSelect = 1"
      )
        span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
        .select-status(v-if="selectType === item.value")
    .content(:style="{'min-height': `${containerHeight}px`}")
      .content-bar(v-if="![3, 5, 6].includes(selectType)")
        .sub-bar(
          :class="`${subTabSelect === item.value ? 'sub-bar-select' : ''}`"
          v-for="(item, index) in tabBarList[selectType - 1].subBar"
          v-if="item.value !== 4 || (item.value === 4 && isOperator)"
          :key="index"
          @click="subTabSelect = item.value"
        ) 
          span(v-if="!item.icon") {{ item.name }}
          img(v-if="item.icon" :src="item.icon", alt="", srcset="")
          span(v-if="item.icon" style="font-size: 16px;") {{ item.name }}
      .bar-content
        personAccount(v-if="selectType === 1 && subTabSelect === 1" @changType="(value) => {selectType = value;subTabSelect = 1}" :info="personAccountInfo")
        firmAccount(v-if="selectType === 1 && subTabSelect === 2" @changType="(value, subValue) => {selectType = value;subTabSelect = subValue}" :info="companyAccountInfo")
        recharge(v-if="selectType === 2" :subTabSelect="subTabSelect")
        rechargeRecord(v-if="selectType === 3")
        deposit(v-if="selectType === 4 && subTabSelect === 1" @changType="(value, subValue) => {selectType = value;subTabSelect = subValue}")
        firmDeposit(v-if="selectType === 4 && subTabSelect === 2" @changType="(value) => {selectType = value;subTabSelect = 1}")
        depositRecord(v-if="selectType === 5")
        incomeRecord(v-if="selectType === 6")
        .notice(v-if="selectType === 2")
          img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
          span · 充值金额只能用于全倾下单，下单后的金额才可开具发票，未下单的充值金额则不能开票。
          span · 一个注册帐号名下支持多个充值账户，包括一个个人账户和多个公司账户。微信与支付宝是充值到个人账户，个人账户可提现至个人支付宝账户。对公转账是充值到公司账户，公司账户则只能提现至同名的公司银行账户。提现时公司与公司、公司与个人间彼此不互通，但下单消费时，可随意选择某账户进行余额支付。
          span · 个人账户余额上限 300 元

</template>
<script>
import personAccount from '../../components/account/personAccount.vue'
import firmAccount from '../../components/account/firmAccount.vue'
import rechargeRecord from '../../components/account/rechargeRecord.vue'
import depositRecord from '../../components/account/depositRecord.vue'
import incomeRecord from '../../components/account/incomeRecord.vue'
import deposit from '../../components/account/deposit.vue'
import firmDeposit from '../../components/account/firmDeposit.vue'
import recharge from '../../components/account/recharge.vue'
import {getCompanyAccountInfoApi, getPersonAccountInfoApi} from '../../api/account'
import {checkAuth} from '@/api/product'

export default {
  components: {
    firmAccount,
    personAccount,
    rechargeRecord,
    depositRecord,
    incomeRecord,
    deposit,
    firmDeposit,
    recharge
  },
  data() {
    return {
      companyAccountInfo: [],
      personAccountInfo: {},
      containerHeight: 0,
      selectType: 1,
      isOperator: false,
      subTabSelect: 1,
      tabBarList: [
        {
          name: '我的账户',
          value: 1,
          subBar: [
            {
              name: '个人账户',
              value: 1
            },
            {
              name: '企业账户',
              value: 2
            }
          ]
        },
        {
          name: '充值',
          value: 2,
          subBar: [
            {
              name: '微信',
              value: 1
              // icon: require('../../assets/weixin.png')
            },
            {
              name: '支付宝',
              // icon: require('../../assets/zhifubao.png'),
              value: 2
            },
            {
              name: '对公转账',
              // icon: require('../../assets/Slicee-icon.png'),
              value: 3
            },
            {
              name: '新增记录',
              // icon: require('../../assets/record.png'),
              value: 4
            }
          ]
        },
        {
          name: '充值记录',
          value: 3
        },
        {
          name: '提现',
          value: 4,
          subBar: [
            {
              name: '个人账户',
              value: 1
            },
            {
              name: '企业账户',
              value: 2
            }
          ]
        },
        {
          name: '提现记录',
          value: 5
        },
        {
          name: '收支明细',
          value: 6
        }
      ]
    }
  },
  created() {
    this.getCompanyAccountInfo()
    this.getPersonAccountInfo()
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
  },
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70 - 36 - 55
    })
    this.selectType = Number(this.$route.query.type || 1)
  },
  methods: {
    // 企业账户
    getCompanyAccountInfo() {
      getCompanyAccountInfoApi().then(res => {
        this.companyAccountInfo = res.data
      })
    },
    // 个人账户
    getPersonAccountInfo() {
      getPersonAccountInfoApi().then(res => {
        this.personAccountInfo = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  overflow: auto;
}
.content {
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}
.sub-bar {
  width: 148px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  img {
    width: 32px;
    margin-right: 6px;
    // background-color: #fff;
  }
}
.sub-bar-select {
  color: #5ad3cd;
  background-image: url('./../../assets/tabBg.png');
  background-size: 100% 100%;
}
.content-bar {
  width: 169px;
  background: #5ad3cd;
  min-height: 100%;
  box-sizing: border-box;
  padding-top: 35px;
  box-sizing: border-box;
  padding-left: 31px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  line-height: 86px;
  text-align: left;
}
.bar-content {
  flex: 1;
  background-color: #fff;
  min-height: 100%;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-left: 56px;
  cursor: pointer;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.notice {
  padding: 24px 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 88px;
  display: block;
  justify-content: flex-start;
  flex-direction: column;
  span {
    display: block;
    margin-bottom: 24px;
  }
}
.notice-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
}
</style>
