<template lang="pug">
.content
  layoutContainer(activityFrom="知识列表页")
    .select-avatar(v-if="selectAvatar.employeeId")
      .avatar-name 作者
      img.avatar-icon(:src="selectAvatar.employeePhotoUrl", alt="", srcset="")
      span.avatar-select-name {{ selectAvatar.employeeName }}
      i(@click.stop="clearAvatar" :class="'el-icon-error close-icon '")
    .select-avatar(v-if="showPromotionProduct")
      .avatar-name 推广品
      span.avatar-select-name 推广品
      i(@click.stop="clearPromotionProduct" :class="'el-icon-error close-icon '")
    .SearchInput(v-if="SearchInput") {{ SearchInput }}
      i.el-icon-error.close-icon(@click="$store.commit('ChangeSearchInput', '')")
    checkRadioGroup(title="职业"
      :list="occupationList"
      ref="checkRadioGroup"
      :activeIndex.sync="queryParams.occupation"
      keyname="name"
    )
    checkRadioGroup(title="排序"
      :list="orderList"
      :activeIndex.sync="queryParams.order"
      :hideAll="true"
      keyname="name"
    )
    .current-Type-Sum
      .sum(v-if="total !== 0") 
        span &nbsp;&nbsp;共
        span {{total}}
        span 知识
    .project
      ul.course-list
        li.list(v-for="(item, index) in classList" :key="`${index}${item.id}`" @click="goToDetail(item.id)")
          .over
            img(:src="item.previewPicUrl ? item.previewPicUrl : item.backgroundPicUrl", alt="", srcset="")
          .title {{item.introduction}}
          .class-title(v-if="!item.previewPicUrl" :style="handleTitleColor(item.backgroundPicUrl)") {{item.name}}
          .hot-logo(v-if="item.hotNum > 0") 热门
          .user-info(@click="checkAuthor($event, item)")
            img.avatar(src="@/assets/avatar_icon.png", alt="", srcset="")
            .user-name {{item.employeeName}}
            img.icon(src="@/assets/time_icon.png", alt="", srcset="")
            .time {{computedTime(item.videoLength)}}
          .price(@click="pay($event, item.id)")
            span(v-if="item.hasDiscount ? item.discountPrice : item.price") ￥{{item.hasDiscount ? item.discountPrice : item.price}}
            span.hasDiscount(v-if="item.hasDiscount && item.price") ￥{{ item.price }}
            .pay(v-if="!item.buyFlag && !item.publisherOrManageOperatorFlag && !item.togetherBuyFlag") 购买
            el-tag.status-tag(type="success" v-if="item.publishStatus === 1 && item.publisherOrManageOperatorFlag") 待审核
            el-tag.status-tag(type="success" v-if="item.publishStatus === 2 && item.publisherOrManageOperatorFlag") 未通过
            el-tag.status-tag(type="success" v-if="item.publishStatus === 3 && item.publisherOrManageOperatorFlag") 已上架
            el-tag.status-tag(type="success" v-if="item.publishStatus === 4 && item.publisherOrManageOperatorFlag") 已下架
      .empty-img
        img.empty(src="./../../assets/listempty.png" v-if="total === 0 && loaded" )
      .left-column
        .publish-options
          .publish-project_btn(@click="myClass" :class="{selected: queryType === 0}")
            img.my-course-icon(src="@/assets/course_icon.png", alt="", srcset="")
            span.my-course-text 我的知识
          .publish-type_list(
              v-for="(item) in publishInfoList" 
              :class="{'selected-tab': (item.val === queryType)}"
              @click="setPublishType(item.val)"
            ) {{item.name}}
        .hot-project
          .title 
            img.hot-icon(src="@/assets/hot_icon.png", alt="", srcset="")
            span 热门
          ul
            li(
              v-for="item in hotCourseList",
              :key="item.id"
              @click="goToDetail(item.id)"
            )
              .project-hot 热
              .project-name {{item.name}}
              .project-budget ￥{{item.price}}
    el-pagination.pagination(
      v-if="total !== 0"
      background
      layout="prev, pager, next"
      :current-page.sync="page"
      :page-size="size"
      :total="total"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      @current-change="currentChange"
  )
  publishDialog(
    :showPublish.sync="showPublishDialog", 
    @publishSuccess="publishSuccess"
    :occupationList="occupationList"
    type="publish"
  )
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="@/assets/persion.png" v-if="showQueryTypeDialog === 4")
      img(src="@/assets/xinxin.png" v-if="showQueryTypeDialog === 5")
      p {{ showQueryTypeDialog === 5 ? `我的粉丝(${fansList.length})` : `我的关注(${attentionList.length})`}}
    .content-dialog(v-if="(showQueryTypeDialog === 4 ? attentionList.length : fansList.length) !== 0")
      .options(v-for="(item, index) in showQueryTypeDialog === 4 ? attentionList : fansList" :class="{'options-last': (index + 1) !== (showQueryTypeDialog === 4 ? attentionList.length : fansList.length)}")
        .name(@click="handleSearchByUser(item)")
          img(:src="item.headPicUrl" v-if="showQueryTypeDialog === 4 && item.headPicUrl")
          p {{showQueryTypeDialog === 4 ? item.nickName: item.userName }}
          img.sex(:src="item.gender === 1 ? boyimg : girlimg" v-if="showQueryTypeDialog === 4")
        p.action(type="primary" @click="userAction(showQueryTypeDialog, item)") {{ showQueryTypeDialog === 5 ? `移除粉丝` : `取消关注` }}
    .content-dialog(v-if="(showQueryTypeDialog === 4 ? attentionList.length : fansList.length) === 0")
      .empty-img
        img.empty(src="@/assets/listempty.png")
</template>

<script>
import {subjectList, hotCourse, pay} from '@/api/subject'
import {followedList, getFansList, removeFans, cancelFollowed} from '@/api/project'
import {getEmployeeOccupationListApi} from '@/api/index'
import {statusList} from '@/config/status.js'
import {setPriceNum, formatTimeFilter} from '@/utils/index'
import {mapState} from 'vuex'
import dialogMask from '@/components/common/dialog.vue'
import publishDialog from '@/components/techVideo/publishDialog.vue'

export default {
  name: 'Project',
  components: {
    dialogMask,
    publishDialog
  },
  data() {
    return {
      page: 1,
      size: 12,
      loaded: false,
      currentUserParticipateProjectStatus: '', // 当前用户参与项目状态 1 我发布的; 2 我申请的; 3 我竞标的; 4 我承接的
      projectList: [], // 项目数据 list
      total: 0,
      hotCourseList: [],
      occupationList: [],
      classList: [],
      selectAvatar: {},
      showPublishDialog: false,
      queryType: -1,
      boyimg: require('@/assets/boy.png'),
      girlimg: require('@/assets/girl.png'),
      id: '',
      name: '',
      showDialog: false,
      showQueryTypeDialog: -1,
      queryParams: {
        order: '',
        occupation: ''
      },
      showPromotionProduct: false,
      publishInfoList: [
        {
          name: '发布知识',
          val: 3
        },
        {
          name: '我发布的',
          val: 1
        },
        {
          name: '我收藏的',
          val: 2
        },
        {
          name: '我的关注',
          val: 4
        },
        {
          name: '我的粉丝',
          val: 5
        }
      ],
      orderList: [
        {
          name: '推荐',
          id: 1
        },
        {
          name: '最热',
          id: 2
        },
        {
          name: '最新',
          id: 3
        }
      ],
      attentionList: [],
      fansList: []
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.subjectList()
      }
    },
    SearchInput(val) {
      this.name = val
      this.isMyCourse = false
      this.id = ''
      this.page = 1
      this.subjectList()
    },
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.$set(this.queryParams, 'occupation', query.occupation ? query.occupation : '')
        console.log('%c [ query.occupation ]-211-「index」', 'font-size:px;', query.occupation ? query.occupation : '')
        console.log('%c [ this.queryParams ]-211-「index」', 'font-size:px;', typeof this.queryParams.occupation)
        // 首页跳转，设置默认我的产品
        if (query.publishType == 1) {
          this.myClass()
        }
      }
    },
    name() {
      this.subjectList()
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState(['SearchInput', 'UserInfo'])
  },
  methods: {
    init() {
      this.id = this.$route.query.standardId
      this.selectAvatar = {
        employeeName: this.$route.query.employeeName,
        employeePhotoUrl: this.$route.query.employeePhotoUrl,
        employeeId: this.$route.query.employeeId
      }
      const query = this.$route.query
      this.$set(this.queryParams, 'occupation', query.occupation ? query.occupation : '')
      this.showPromotionProduct = this.$route.query.show_promotion_product
      this.getEmployeeOccupationListApi()
      this.subjectList()
      this.hotCourse()
      this.getFansAndFollow()
    },
    setPriceNum,
    formatTimeFilter,
    userAction(action, info) {
      // 5 移出粉丝
      if (action === 5) {
        this.$confirm('确认移除粉丝吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          removeFans({
            fansUserId: info.userId,
            objectType: '知识视频'
          }).then(res => {
            this.$message.success('操作成功')
            this.getFansAndFollow()
          })
        })
      } else {
        // 取消关注
        this.$confirm('确认取消关注吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          cancelFollowed({
            objectId: info.employeeId,
            objectType: '知识视频'
          }).then(res => {
            this.$message.success('操作成功！')
            this.getFansAndFollow()
          })
        })
      }
    },
    handleSearchByUser(info) {
      this.$set(this, 'selectAvatar', {
        employeeName: info.nickName,
        employeePhotoUrl: info.headPicUrl,
        employeeId: info.userId
      })
      this.page = 1
      this.queryParams.occupation = ''
      this.queryType = -1
      this.subjectList()
      this.showDialog = false
    },
    getFansAndFollow() {
      this.followedList()
      this.getFansList()
    },
    followedList() {
      followedList().then(res => {
        if (res.code === 200) {
          this.attentionList = res.data.followEmployeeVos
        }
      })
    },
    getFansList() {
      getFansList().then(res => {
        if (res.code === 200) {
          this.fansList = res.data.followedVos
        }
      })
    },

    checkAuthor(e, info) {
      e.preventDefault()
      e.stopPropagation()
      this.$set(this, 'selectAvatar', {
        employeeName: info.employeeName,
        employeePhotoUrl: info.employeePhotoUrl,
        employeeId: info.employeeId
      })
      this.page = 1
      this.queryParams.occupation = ''
      this.queryType = -1
      this.subjectList()
      console.log('%c [ selectAvatar ]-253', 'font-size:13px; background:pink; color:#bf2c9f;', this.selectAvatar)
      // this.$router.push({
      //   path: `/personal/detail/${info.employeeId}`
      // })
    },
    clearAvatar() {
      this.selectAvatar = {}
      this.page = 1
      this.queryParams.occupation = ''
      this.subjectList()
    },
    clearPromotionProduct() {
      this.showPromotionProduct = false
      this.page = 1
      this.subjectList()
    },
    computedTime(seconds) {
      // 计算小时数，通过除以3600取整（因为1小时 = 3600秒）
      const hours = Math.floor(seconds / 3600)
      // 计算剩余的秒数
      seconds %= 3600
      // 计算分钟数，除以60取整（因为1分钟 = 60秒）
      const minutes = Math.floor(seconds / 60)
      // 最终剩余的秒数就是秒数部分
      seconds %= 60

      // 将小时、分钟、秒数按照格式要求补零并拼接成字符串
      const hoursStr = hours.toString().padStart(2, '0')
      const minutesStr = minutes.toString().padStart(2, '0')
      const secondsStr = seconds.toString().padStart(2, '0')

      return `${hoursStr === '00' ? `${minutesStr}:${secondsStr}` : `${hoursStr}:${minutesStr}:${secondsStr}`}`
    },
    getEmployeeOccupationListApi() {
      getEmployeeOccupationListApi().then(res => {
        this.occupationList = res.data
        console.log('%c [ this.occupationList ]-360-「index」', 'font-size:px;', this.occupationList)
      })
    },
    handleTitleColor(url) {
      const imgName = url.split('/')[url.split('/').length - 1]
      let color = ''
      switch (imgName) {
        case '1.png':
          color = 'rgba(73, 93, 173, 1)'
          break
        case '2.png':
          color = 'rgba(255, 104, 31, 1)'
          break
        case '3.png':
          color = 'rgba(92, 43, 255, 1)'
          break
        default:
          color = '#fff'
          break
      }
      return {
        color
      }
    },
    subjectList() {
      this.loaded = false
      const {occupation, order} = this.queryParams
      const params = {
        pageNum: this.page,
        pageSize: this.size,
        order: order || '',
        name: this.name,
        occupation,
        showPromotionProduct: this.showPromotionProduct,
        queryType: this.queryType, //  0-我的知识; 1-我的发布; 2-我的收藏;
        // standardId 技术标准 ID
        // useWeight 查询权重 ID
        employeeId: this.selectAvatar.employeeId // 作者 ID
      }
      if (this.id) {
        params.standardId = this.id
      }
      // /course/list?name=&occupation=1&order=1&pageNum=1&pageSize=8&standardId=1&useWeight=true&employeeId=1&queryType=1
      subjectList(params).then(res => {
        if (res.code === 200009) {
          this.$store.commit('ChangeSearchInput', '')
          this.$router.push({
            path: '/login'
          })
        }
        this.classList = res.data.records
        this.total = res.data.total
        this.loaded = true
        this.id = ''
      })
    },
    publishSuccess() {
      this.currentChange(1)
    },
    currentChange(page) {
      this.page = page
      this.subjectList()
    },
    setStatus(status) {
      return statusList[status]
    },
    hotCourse() {
      hotCourse().then(res => {
        this.hotCourseList = res.data
      })
    },
    resetQueryParams() {
      const queryParams = {
        order: '',
        occupation: ''
      }
      this.queryType = -1
      this.selectAvatar = {}
      this.$set(this, 'queryParams', queryParams)
    },
    pay(e, id) {
      e.preventDefault()
      e.stopPropagation()
      pay({id}).then(res => {
        if (res.code === 200009) {
          this.$router.push({
            path: '/login'
          })
        }
        const {code, data} = res
        if (code === 200 && data) {
          this.$router.push({
            path: '/pay',
            query: {
              courseId: id,
              orderId: res.data
            }
          })
        } else {
          this.$dialog.toast(res.msg)
        }
      })
    },
    goToDetail(id) {
      this.$store.commit('ChangeSearchInput', '')
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: id}
      })
    },
    myClass() {
      this.name = ''
      this.isMyCourse = !this.isMyCourse
      this.queryType = this.queryType === 0 ? -1 : 0
      this.page = 1
      this.$store.commit('ChangeSearchInput', '')
      this.subjectList()
    },
    setPublishType(type) {
      if (this.queryType === type) {
        this.queryType = -1
        this.currentChange(1)
        return
      }
      // 发布知识
      if (type === 3) {
        if (!this.UserInfo || !this.UserInfo.id) {
          this.$router.push('/login')
          return
        }
        this.showPublishDialog = true
        return
      }
      if (type === 4) {
        this.showQueryTypeDialog = type
        this.showDialog = true
        return
      }
      if (type === 5) {
        this.showQueryTypeDialog = type
        this.showDialog = true
        return
      }
      this.resetQueryParams()
      this.queryType = type
      // this.currentChange(1)
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 6px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  > i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
}

.empty-img {
  margin: 0 auto;
  overflow: hidden;
}

.empty {
  width: 600px;
  display: block;
  margin: 80px auto;
}

.selected {
  border: 1px solid #5ad3cd !important;
  background: rgba(90, 211, 205, 1) !important;
  color: #5ad3cd !important;
}

.selected-tab {
  border: 1px solid #5ad3cd !important;
  background: #fff;
  color: #5ad3cd !important;
}

.project {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 1205px;

  .course-list {
    width: 915px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .list {
    // width: 24%;
    // margin-right: 1%;
    // padding: 20px 30px 20px 120px;
    // padding-bottom: 20px;
    width: 285px;
    margin-right: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 21px;
    border-radius: $radius;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 329px;
    .over {
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
    }

    .class-title {
      position: absolute;
      top: 48px;
      left: 12px;
      width: 165px;
      color: #fff;
      font-weight: 900;
      font-size: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #666666;
      height: 50px;
      line-height: 25px;
      padding: 0;
      margin: 14px 16px 15px 16px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
      word-wrap: break-word; //允许单词内断句，⾸先会尝试挪到下⼀⾏，看看下⼀⾏的宽度够不够，不够的话就进⾏单词内的断句
    }

    .user-info {
      margin: 0 0 16px 16px;
      position: relative;
    }

    .avatar {
      width: 15px;
      height: 17px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 4px;
    }

    .icon {
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }

    .user-name {
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
      font-size: 18px;
      margin-right: 20px;
      color: #666666;
    }

    .time {
      display: inline-block;
      vertical-align: top;
      color: #666666;
      // position: absolute;
      // right: 10px;
      // top: 0;
      line-height: 20px;
    }

    .price {
      margin: 0 0 16px 16px;
      position: relative;
      color: #ff724c;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 33px;
      .hasDiscount {
        text-decoration: line-through;
        font-size: 17px;
        color: #666666;
        margin-left: 10px;
        opacity: 0.6;
      }
      .pay {
        width: 64px;
        height: 30px;
        position: absolute;
        right: 16px;
        top: 2px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        background: #ff724c;
        border-radius: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .status-tag {
        position: absolute;
        right: 16px;
        top: 2px;
      }
    }
  }

  .left-column {
    width: 290px;

    .publish-options,
    .hot-project {
      background: #fff;
      margin-bottom: 16px;
      box-sizing: border-box;
      border-radius: $radius;
    }

    .hot-project {
      padding: 20px 15px 8px;

      .title {
        font-size: 18px;
        font-family: $fontFamily;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .hot-icon {
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }
      }

      ul {
        padding-top: 15px;
        border-top: 2px solid #dcdfe6;

        li {
          margin-bottom: 13px;
          cursor: pointer;

          .project-type {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
            background-color: $btnColor;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            vertical-align: top;
          }

          .project-hot {
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            background: rgba(247, 72, 25, 0.1);
            border-radius: 2px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #f74819;
            margin-right: 6px;
          }

          .project-name {
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            max-width: 180px;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: top;
          }

          span {
            height: 16px;
            display: inline-block;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5ad3cd;
            line-height: 13.5px;
            border: 1px solid $btnColor;
            padding: 0 5px;
            margin-left: 6px;
            margin-top: 2px;
            border-radius: 10px;
            vertical-align: top;
          }

          .project-budget {
            float: right;
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 500;
            color: #ff724c;
            line-height: 20px;
          }
        }
      }
    }

    .publish-options {
      padding: 20px 15px 6px;
      overflow: hidden;
      margin-bottom: 10px;

      .publish-project_btn {
        width: 260px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        font-family: $fontFamily;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #eeeeee;
        background: rgba(90, 211, 205, 0.6);
        border-radius: 4px;
        margin-bottom: 20px;
      }

      .my-course-icon {
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 6px;
        padding-top: 15px;
        box-sizing: content-box;
      }

      .my-course-text {
        vertical-align: top;
        color: #ffffff;
      }

      .publish-type_list {
        width: 125px;
        height: 48px;
        font-size: 18px;
        border: 1px solid #eeeeee;
        color: #999999;
        font-family: $fontFamily;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
        float: left;
        cursor: pointer;
        border-radius: $radius;

        &:nth-child(2n) {
          margin-right: 10px;
        }
      }

      // .publish-type_list:nth-child(1) {
      //   margin-right: 10px;
      // }
    }
  }
}

.hot-logo {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: #f74819;
  border-radius: 8px 30px 30px 8px;
  padding-left: 10px;
  padding-right: 13px;
}

.pagination {
  text-align: center;
}
.title {
  padding-left: 20px;
  margin-bottom: 31px;
  img {
    width: 31px;
    height: 30px;
    margin-top: 2px;
    margin-right: 10px;
    display: inline-block;
    font-size: 0;
  }
  p {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 33px;
    display: inline-block;
    vertical-align: top;
  }
}
.options {
  width: 80%;
  margin: 0 auto 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 30px 0;
  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 12px;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
    }
    .sex {
      position: absolute;
      top: -5px;
      left: 38px;
      width: 21px;
      height: 21px;
    }
  }
}
.options-last {
  border-bottom: 1px solid #e9e9e9;
}
.select-avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .avatar-name {
    width: 160px;
    padding-left: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }
  .avatar-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 6px;
  }
  .close-icon {
    cursor: pointer;
  }
  .avatar-select-name {
    margin-right: 10px;
  }
}
.content-dialog {
  height: 371px;
}
.current-Type-Sum {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin: 30px 0;

  span {
    display: inline-block;
    padding-left: 6px;

    &:nth-child(2) {
      color: rgba(255, 114, 76, 1);
    }
  }
}
.empty {
  width: 400px;
  display: block;
  margin: 80px auto;
}
.action {
  background: #5ad3cd;
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
</style>
