<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .promotion-page
      //- .promotion-header
        //- .icon
        //- p 手动关联
      .options-form
        .label 关联方式
        el-select(v-model="type" placeholder="请选择关联方式" style="width: 380px;")
          el-option(label="注册ID" :value="1")
          el-option(label="外部平台号" :value="2")
      .options-form(v-if="type === 1")
        .label 对方ID
        el-input(v-model="registerUserPhoneNumber" placeholder="请输入对方注册手机号" style="width: 380px;")
      .options-form(v-if="type === 2")
        .label 所属平台
        el-select(v-model="belongPlatformId" placeholder="请选择所属平台" style="width: 380px")
          el-option(v-for="item in platFormList" :key="item.id" :label="item.name" :value="item.id")
      .options-form(v-if="type === 2")
        .label 平台号
        el-input(v-model="platformUserId" placeholder="请输入平台号" style="width: 380px;")
      .options-form
        .label 沟通记录
        //- .order-img-list
          //- img(v-for="item in orderImgList" :src="item" :key="item")
        el-upload(:action="rule.uploadUrl"
          :class="picture.length > 2 ? 'hide' : ''"
          :headers="{token}"
          list-type="picture-card"
          :file-list="fileList"
          :disabled="rule.disable"
          :accept="rule.limitAccepts"
          :with-credentials="true"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :on-change="imageChange"
          :on-error="imgUploadError"
          :on-remove="onRemove"
          name="picFile"
          :limit="3"
          v-loading="loading"
          element-loading-text="上传中")
          i.el-icon-plus
      .options-form
        .label 对方属于
        .target-type(:class="identityType === '公司' && 'target-type-select'" @click="identityType = '公司'") 公司
        .target-type(:class="identityType === '个人' && 'target-type-select'" @click="identityType = '个人'") 个人
      .options-form(v-show="identityType === '公司'")
        .label 公司全称
        el-input(v-model="companyName" placeholder="请输入公司全称" style="width: 380px;")
      .options-form(v-show="identityType === '个人'")
        .label 昵称
        el-input(v-model="nickName" placeholder="请输入昵称(选填)" style="width: 380px;")
      .options-form(v-show="identityType === '个人'")
        .label 姓名
        el-input(v-model="name" placeholder="请输入姓名(选填)" style="width: 380px;")
      .btns
        .action(@click="create" style="margin-left: 196px; padding: 0px 70px; font-size: 20px; height: 56px; border-radius: 34px 34px 34px 34px;") 新增
      .notice(v-if="tips.length")
        img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
        div(style="flex: 1")
          span(v-for="item in tips") · {{item}}
    .promotion-page
      .promotion-header
        .icon
        p 我的推广链接
      .promotion-content
        p 发送下述推广链接给对方，其来到全倾平台注册并产生消费或收入后，您即可获得佣金。效果和上面手动关联是一样的。
        p(style="margin-top: 12px") https://www.quanqingkeji.com/#/home&promoter_id={{userId}}&promote_type=promote
      .btns
        .action(@click="copy")
          img(src="../../assets/copy.png" alt="" style="width: 24px; height: 24px; margin-right: 10px")
          span 复制
    .promotion-page(style="border-bottom: none")
      .promotion-header
        .icon
        p 推广场景
      div(style="flex: 1; margin: 0 0 40px 60px")
          div.scene-spot-container(v-for="item in promotionSceneList")
            span.scene-spot 
            span {{item}}
  .content-container(v-if="selectType === 2" style="display: flex;flex-direction: row;")
    div(style="flex: 1; margin-right: 30px")
      .search-tab(style="justify-content: flex-end;margin-bottom:10px;")
        .search-account
          el-input(v-model="phoneNumber" placeholder="请输入注册者手机号" style="width: 180px")
          img.search-icon(src="/img/search-icon.png" @click="searchClick")
      el-table(:data="phoneOrderInfo.records" style="width: 100%")
        el-table-column(prop="registerUserPhoneNumber" label="注册者手机号")
        el-table-column(prop="registerTime" label="注册时间")
      el-pagination(
        class="el-pagination"
        background
        :page-size="pagePhoneSize"
        :pager-count="11"
        layout="prev, pager, next"
        @current-change="currentPhoneChange"
        :total="phoneTotal"
        style="text-align: right; margin-top: 24px"
      )
    div
      .common(style="width: 320px;height: 120px;margin-bottom: 0px;")
        .options
          .name
            span 有
            span(style="margin: 0 10px; font-size: 24px; font-weight: bold; text-align: center; display: inline-block") {{phoneOrderInfo.registerTotal}} 
            span 人通过我的推广注册了
      .notice.notice-register
        img.notice-icon(style="opacity: 0.5; width: 32px; height: 32px" src="./../../assets/notice-icon.png", alt="", srcset="")
        div(style="flex: 1")
          span(style="color: rgb(153, 153, 153); font-size: 14px; margin: 0 0 0 24px") 只记录了通过推广链接注册的，手动关联的因为用户已经注册过了就无需记录
  .content-container(v-if="selectType === 3")
    .common
      .options
        .label {{orderInfo.total}}
        .name(style="font-weight: 600; font-size: 16px; color: #ACACAC") 导流来的用户下单数
      .options
        .label
          span.icon  ¥
          span {{orderInfo.orderTotalAmount}}
        .name(style="font-weight: 600; font-size: 16px; color: #ACACAC") 下单总金额

      .options
        .label
          span.icon  ¥
          span {{orderInfo.anticipativeTotalCommission}}
        .name(style="font-weight: 600; font-size: 16px; color: #ACACAC") 佣金总额
      .options
        .label
          span.icon  ¥
          span {{orderInfo.anticipativeEffectedTotalCommission}}
        .name(style="font-weight: 600; font-size: 16px; color: #ACACAC") 已生效佣金
          el-tooltip.item(effect="dark" content="订单超过可退款期限，用户没有退款且没有申请中的退款时，佣金即生效。具体可到个人中心页的我的收入栏查阅" placement="bottom")
            i.el-icon-question
    .search-tab
      .search-account
        span(style="width: 48px;display: inline-block;") 筛选：
        el-select(v-model="status" placeholder="请选择状态" @change="(value) => handleSelectChange(value, 'status')")
          el-option(label="全部" :value="-1")
          el-option(label="未生效" :value="0")
          el-option(label="已生效" :value="1")
      .search-code
        span(style="width: 48px;display: inline-block;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px;width: 200px;")
          el-option(label="下单人 ID" value="1")
          el-option(label="订单号" value="2")
        el-input(v-model="queryContent" placeholder="请输入内容" style="width: 200px;")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    el-table(:data="orderInfo.items" style="width: 100%; margin-top: 24px")
      el-table-column(prop="orderUserPhoneNumber" label="下单人ID")
      el-table-column(prop="orderId" label="订单号" width="210")
      el-table-column(prop="orderAmount" label="订单金额")
        template(slot-scope="scope")
          span ¥ {{scope.row.orderAmount}}
      el-table-column(prop="anticipativeCommission" label="预期佣金")
        template(slot-scope="scope")
          span(style="display: inline-block;margin-right:10px;") ¥ {{scope.row.anticipativeCommission}}
          el-tag(:type="scope.row.commissionIsEffected ? 'success': 'info'" style="margin-right: 5px") {{scope.row.commissionIsEffected ? '已生效' : '未生效'}}
      el-table-column(prop="orderTime" label="下单时间")
    el-pagination(
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right; margin-top: 24px"
    )
  .content-container(v-if="selectType === 4")
    .notice-what-for.notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1; margin-left: 48px")
        div(v-for="item in promotionSceneList" style="margin-bottom: 32px")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {
  getTips,
  getGuidanceScenes,
  registerList,
  orderRecords,
  qaList,
  saveRelation,
  platforms
} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'
import {fileRemove} from '@/api/index'
export default {
  data() {
    return {
      tabBarList: [
        {
          name: '新增关联',
          value: 1
        },
        {
          name: '注册记录',
          value: 2
        },
        {
          name: '下单记录',
          value: 3
        },
        {
          name: '什么是“推广”',
          value: 4
        }
      ],
      selectType: 1,
      selectedConnectionType: '注册ID', // 关联方式
      targetUserId: '', // 对方ID
      platForm: '', // 所属平台
      platFormList: [], //平台列表
      platformUserId: '', // 平台号
      rule: {
        uploadUrl: 'https://www.quanqingkeji.com/api/file/uploadZipImage',
        limitSize: 10, // MB
        limitAccepts: '.jpg,.png,.JPG,.PNG,.jpeg',
        // limitTip: '仅能上传1张图片，不超过10M，格式：png、jpg',
        acceptsErrorMsg: '上传图片只能是png,jpg格式',
        disable: false
      },
      token: '',
      orderImgList: [], // 图片列表
      identityType: '公司',
      companyName: '',
      nickName: '',
      name: '',
      loading: false,
      picture: {
        url: ''
      },
      tips: [],
      userId: '',
      promotionSceneList: [],
      phoneOrderInfo: {},
      phoneNumber: '',
      pageSize: 10,
      pagePhoneSize: 10,
      page: 1,
      pageNum: 1,
      phoneTotal: 0,
      total: 0,
      promoteType: 'promote',
      orderInfo: {},
      status: -1,
      queryType: '',
      queryContent: '',
      isTips: [],
      fileList: [],
      type: '',
      registerUserPhoneNumber: '',
      belongPlatformId: '',
      platFormId: ''
    }
  },
  props: {},
  mounted() {
    this.token = localStorage.getItem('TOKEN')
    // tips（导流）
    getTips({position: '推广-新增关联'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    // 用户id
    currentLoginUser().then(res => {
      this.userId = res.data.userId
    })
    // 导流场景文案
    getGuidanceScenes({position: '推广-推广场景'}).then(res => {
      this.promotionSceneList = res.data
    })
    // 什么是"导流"
    qaList({position: '推广-什么是推广'}).then(res => {
      this.isTips = res.data
    })
    platforms().then(res => {
      this.platFormList = res.data
    })
    this.searchPhone()
    this.search()
  },
  watch: {
    selectType() {
      this.page = 1
      this.searchPhone()
      this.pageNum = 1
      this.search()
    },
    queryContent() {
      this.page = 1
      this.search()
    },
    fileList(val) {
      console.log('%c [ val ]-271', 'font-size:13px; background:pink; color:#bf2c9f;', val)
    }
  },
  methods: {
    searchClick() {
      this.pageNum = 1
      this.searchPhone()
    },
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.page = 1
      this.searchPhone()
      this.pageNum = 1
      this.search()
    },
    beforeAvatarUpload(file) {
      let extName = file.name.substring(file.name.lastIndexOf('.'))
      if (this.rule.limitAccepts.split(',').findIndex(e => e === extName) < 0) {
        this.$message.warning(this.rule.acceptsErrorMsg)
        return false
      }
      // 图片大小检查
      if (file.size > 1024 * 1024 * 10) {
        this.$message.warning('上传图片大小不能超过' + this.rule.limitSize + 'MB')
        return false
      }
      this.loading = true
      return true
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log('%c [ fileList ]-290', 'font-size:13px; background:pink; color:#bf2c9f;', fileList)
      console.log('%c [ file ]-290', 'font-size:13px; background:pink; color:#bf2c9f;', file)
      this.loading = false
      this.picture = fileList
    },
    onRemove(file) {
      fileRemove({url: file.response.data})
    },
    imageChange(file, fileList) {
      this.picture = fileList
    },
    imgUploadError(err, file, fileList) {
      this.$message.error('上传图片失败!')
      this.loading = false
    },
    create() {
      if (this.type === '') {
        this.$message.error('请选择关联方式!')
        return
      }
      if (this.identityType === '公司') {
        if (this.companyName === '') {
          this.$message.error('请输入公司名称!')
          return
        }
      }
      // if (this.identityType === '个人') {
      //   if (this.nickName === '') {
      //     this.$message.error('请输入昵称!')
      //     return
      //   }
      //   if (this.name === '') {
      //     this.$message.error('请输入姓名!')
      //     return
      //   }
      // }
      const params = {
        type: this.type,
        registerUserPhoneNumber: this.registerUserPhoneNumber,
        belongPlatformId: this.belongPlatformId,
        platformUserId: this.platformUserId,
        identityType: this.identityType,
        companyName: this.companyName,
        nickName: this.nickName,
        name: this.name,
        picUrls: Array.isArray(this.picture) ? this.picture.map(item => item.response.data) : []
      }
      saveRelation(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.type = ''
          this.registerUserPhoneNumber = ''
          this.belongPlatformId = ''
          this.platformUserId = ''
          this.identityType = '公司'
          this.companyName = ''
          this.nickName = ''
          this.name = ''
          this.picture = []
          this.fileList = []
          return
        }
        this.$message.error(res.data)
      })
    },
    copyTextToClipboard(text) {
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      input.select() // 选择输入框的内容
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
    },
    copy() {
      this.copyTextToClipboard(`https://www.quanqingkeji.com/#/home?promoter_id=${this.userId}&promote_type=share`)
    },
    // 注册记录分页
    currentPhoneChange(val) {
      this.pageNum = val
      this.searchPhone()
    },
    // 订单分页
    currentChange(val) {
      this.page = val
      this.search()
    },
    // 下拉选change事件
    handleSelectChange(value, type) {
      if (type === 'status') {
        this.status = value
      }
      this.search()
    },
    // 注册记录
    searchPhone() {
      registerList({
        promoteType: this.promoteType,
        phoneNumber: this.phoneNumber,
        pageNum: this.pageNum,
        pageSize: this.pagePhoneSize
      }).then(res => {
        this.phoneTotal = res.data.total
        this.phoneOrderInfo = res.data
      })
    },
    // 订单记录
    search() {
      orderRecords({
        promoteType: this.promoteType,
        status: this.status,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.orderInfo = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.promotion-page {
  margin-bottom: 20px;
  border-bottom: 1px dashed #dcdfe6;
  .promotion-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    p {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      color: rgb(51, 51, 51);
      line-height: 26px;
    }
  }
  .promotion-content {
    margin: 0 0 40px 60px;
    p {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      color: rgb(51, 51, 51);
      line-height: 26px;
    }
  }
  .icon {
    width: 8px;
    height: 8px;
    margin: 0 30px;
    border-radius: 50%;
    background-color: #5ad3cd;
  }
}
.guidance-page-content {
  flex: 1;
  p {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    color: rgb(51, 51, 51);
    line-height: 26px;
    margin-bottom: 30px;
  }
}
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
  /deep/th {
    font-size: 18px;
  }
  /deep/.el-table__body {
    font-size: 17px;
  }
  /deep/.el-table__empty-text {
    font-size: 17px;
  }
  /deep/.el-tag.el-tag--success {
    border-color: #15b6ae;
    color: #15b6ae;
  }
  /deep/.el-tag {
    background: #ffffff;
    border-radius: 24px;
    height: 28px;
    line-height: 28px;
    padding: 0 16px;
  }
  /deep/.el-table__row {
    height: 80px;
  }
  /deep/.el-table__empty-block {
    min-height: 80px;
  }
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.options-form {
  margin-left: 65px;
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}
.target-type {
  height: 30px;
  padding: 0 24px;
  line-height: 30px;
  background: #afb1b3;
  margin-right: 16px;
  border-radius: 16px;
  position: relative;
  top: 4px;
  color: #ffffff;
  cursor: pointer;
}
.target-type-select {
  background: #7a7b7c;
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  display: flex;
  align-items: center;
  border-radius: 34px;
  background: #5ad3cd;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
  margin-left: 60px;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.notice {
  padding: 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  span {
    margin-top: 24px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #6d6d6d;
    line-height: 28px;
  }
  p {
    margin: 8px 0;
    color: #15b6ae;
    font-weight: bold;
    font-size: 22px;
  }
}
.notice-what-for {
  display: flex;
}

.notice-icon {
  width: 40px;
  height: 40px;
}
.notice-register {
  width: 320px;
  margin-top: 20px;
  display: flex;
}
.scene-spot-container {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.scene-spot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 30px;
  background-color: #5ad3cd;
}
.common {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
  .label {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 6px;
    vertical-align: bottom;
    align-items: flex-end;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    font-style: normal;
    text-transform: none;

    .icon {
      font-size: 32px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
