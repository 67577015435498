import request from '@/utils/request'
// 标准分类+ 后台相


// 购买(加入选购清单)
export const getStandardBuyApi = params =>
  request({
    url: '/standard/buy',
    method: 'post',
    data: JSON.stringify(params)
  })

// 购买清单
export const getStandardBuyListApi = params =>
  request({
    url: '/standard/buyList',
    method: 'get',
    params
  })


// 取消购买(移除选购清单)
export const getStandardBuyListCancelBuyApi = params =>
  request({
    url: '/standard/buyList/cancelBuy',
    method: 'delete',
    data: JSON.stringify(params)
  })
// 取消标准包 (移除选购清单)
export const getStandardBuyListCancelPackageApi = params =>
  request({
    url: '/standard/buyList/cancelPackage',
    method: 'delete',
    data: JSON.stringify(params)
  })

export const getStandardQueryStandardMetricInfos = params =>
  request({
    url: '/standard/queryStandardMetricInfos',
    method: 'get',
    params
  })


// 获取标准分组分类

export const getStandardGetClassGroupApi = params =>
  request({
    url: '/standard/getClassGroup',
    method: 'get',
    params
  })
// 标准列表
export const getStandardListApi = params =>
  request({
    url: '/standard/list',
    method: 'get',
    params
  })


// 点击求助专家，先拿到当前技术标准对应的domainIdList，再查询员工列表/standard/getStandardEmployeeDomains/{standardId}

export const getStandardgetStandardEmployeeDomainsApi = params =>
  request({
    url: `/standard/getStandardEmployeeDomains/${params.standardId}`,
    method: 'get',
    params
  })




// 查询包选项的属性
export const getStandardPackageOptionItemsApi = params =>
  request({
    url: '/standard/package/option/items',
    method: 'get',
    params
  })

// 保存包选项的属性
export const getStandardPackageOptionSaveApi = params =>
  request({
    url: '/standard/package/option/save',
    method: 'post',
    data: JSON.stringify(params)
  })


// 标准包列表
export const getStandardPackageListApi = params =>
  request({
    url: '/standard/packageList',
    method: 'get',
    params
  })
// 查询项目预设包
export const getStandardPackagePresetListApi = params =>
  request({
    url: '/standard/packagePresetList',
    method: 'get',
    params
  })
// 保存项目预设包
export const getStandardSaveProjectPackagePresetApi = params =>
  request({
    url: '/standard/saveProjectPackagePreset',
    method: 'post',
    data: JSON.stringify(params)
  })
// 支付
export const getStandardPayApi = params =>
  request({
    url: '/standard/pay',
    method: 'post',
    data: JSON.stringify(params)
  })
// 查询权重包列表
export const getStandardQueryWeightPackageListApi = params =>
  request({
    url: '/standard/queryWeightPackageList',
    method: 'get',
    params
  })

// 技术产品分类列表
export const getTechProductClassListApi = params =>
  request({
    url: '/tech/productClassList',
    method: 'get',
    params
  })

// 技术产品列表
export const getTechProductListApi = params =>
  request({
    url: '/tech/productList',
    method: 'get',
    params
  })

// 技术标准分类列表
export const getTechStandardClassListApi = params =>
  request({
    url: '/tech/standardClassList',
    method: 'get',
    params
  })

// 技术标准列表
export const getTechStandardListApi = params =>
  request({
    url: '/tech/standardList',
    method: 'get',
    params
  })

// 编辑产品
export const techEditProductApi = params =>
  request({
    url: `/tech/editProduct`,
    method: 'post',
    data: JSON.stringify(params)
  })

// 编辑标准
export const techEditStandardApi = params =>
  request({
    url: `/tech/editStandard`,
    method: 'post',
    data: JSON.stringify(params)
  })

// 搜索结果页接口
export const searchApi = params =>
  request({
    url: '/search',
    method: 'get',
    params
  })

// 搜索结果页接口
export const getStandardEmployeeDomains = params =>
  request({
    url: `/tech/getStandardEmployeeDomains/${params.standardId}`,
    method: 'get',
    params
  })
