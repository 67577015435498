<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options-form
      .label 项目形式
      el-select(v-model="projectType" placeholder="请选择项目模式" style="width: 380px;")
        el-option(label="网站" value="网站")
        el-option(label="app" value="app")
        el-option(label="系统" value="系统")
        el-option(label="小程序" value="小程序")
    .options-form
      .label 对方手机号
      el-input(v-model="customerPhoneNumber" placeholder="请输入对方手机号" style="width: 380px;")
    .btns
      .action(@click="create" style="margin-left: 130px; padding: 0px 70px; font-size: 20px; height: 56px; border-radius: 34px 34px 34px 34px;") 新增
    .notice(v-if="tips.length")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 2")
    .search-tab
      .search-account
        el-select(v-model="status" placeholder="请选择状态")
          el-option(label="全部状态" :value="-1")
          el-option(label="待跟进" :value="0")
          el-option(label="已跟进" :value="1")
      .search-code( v-if="isOperator")
        span(style="width: 48px; display: inline-block;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px; width: 200px;")
          el-option(label="对方手机号" :value="1")
          el-option(label="引荐人ID" :value="2")
          el-option(label="处理人ID" :value="3")
        el-input(v-model="queryContent" placeholder="请输入内容" style="width: 200px;")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="recommendInfo.records.length === 0")
    .search-item(v-for="(item, index) in recommendInfo.records" :key="item.id" :class="index === recommendInfo.records.length - 1? 'search-item-no-line': ''")
      .search-item-container
        .item-container
          span.item-left 项目形式 
          span {{item.projectType}}
        .item-container
          span.item-left 对方手机号
          span {{item.customerPhoneNumber}}
        .item-container
          span.item-left 引荐人ID
          span {{item.recommendUserPhoneNumber}}
        .item-container
          span.item-left 状态
          span.item-status {{statusMap[item.status]}}
        .item-container
          span.item-left 处理人ID
          span {{item.dealUserPhoneNumber}}
        .item-container
          span.item-left 创建时间
          span {{item.createTime}}
      .action-btn(v-if="item.status === 0 && hasManageAuth" @click="() => handleChangeStatus(item)") 设为已跟进
    el-pagination(
      v-if="recommendInfo.records.length > 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 3")
    .notice-what-for.notice(style="background-color: #fff;margin: 0;" )
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1; margin-left: 48px")
        div(v-for="item in isTips" style="margin-bottom: 32px")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {getRecommendList, getTips, qaList, postRecommendStatus, createRecommend} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'
import {checkAuth} from '@/api/product'

export default {
  data() {
    return {
      tabBarList: [
        {
          name: '引荐项目',
          value: 1
        },
        {
          name: '引荐列表',
          value: 2
        },
        {
          name: '什么是“引荐”',
          value: 3
        }
      ],
      selectType: 1,
      projectType: '网站',
      status: -1,
      queryType: 1,
      queryContent: '',
      page: 0,
      recommendInfo: {},
      pageSize: 10,
      total: 0,
      customerPhoneNumber: '',
      tips: [],
      statusMap: {
        1: '已跟进',
        0: '待跟进'
      },
      isOperator: false,
      hasManageAuth: false,
      isTips: []
    }
  },
  props: {},
  watch: {
    selectType() {
      if (this.selectType === 2) {
        this.currentChange(1)
      }
    },
    status() {
      if (this.selectType === 2) {
        this.currentChange(1)
      }
    },
  },
  created() {
    currentLoginUser().then(res => {
      this.hasManageAuth = res.data.hasManageAuth
    })
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
  },
  mounted() {
    getTips({position: '引荐-引荐项目'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    this.search()
    // 什么是"引荐"
    qaList({position: '引荐-什么是引荐'}).then(res => {
      this.isTips = res.data
    })
  },
  methods: {
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.page = 1
      this.search()
    },
    // 订单记录
    search() {
      getRecommendList({
        status: this.status,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.recommendInfo = res.data
        this.total = res.data.total
      })
    },
    // 订单分页
    currentChange(val) {
      this.page = val
      this.search()
    },
    create() {
      if (!/^1[3-9]\d{9}$/.test(this.customerPhoneNumber)) {
        this.$message.warning('请输入正确手机号')
        return
      }
      const params = {
        projectType: this.projectType,
        customerPhoneNumber: this.customerPhoneNumber
      }
      createRecommend(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.projectType = '网站'
          this.customerPhoneNumber = ''
          this.search()
          return
        }
        this.$message.error(res.data)
      })
    },
    handleChangeStatus(item) {
      const params = {
        id: item.id
      }
      postRecommendStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.search()
          return
        }
        this.$message.error(res.data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed #dcdfe6;
  padding: 20px;
  margin-bottom: 20px;
}
.search-item-no-line {
  border-bottom: none;
}
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.options-form {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 40px;
  padding: 0 20px;
}
.notice {
  padding: 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  span {
    margin-top: 24px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #6D6D6D;
    line-height: 28px;
  }
  p {
    margin: 8px 0;
    color: #15b6ae;
    font-weight: bold;
    font-size: 22px
  }
}
.notice-what-for {
  display: flex;
}
.notice-icon {
  width: 40px;
  height: 40px;
}
.search-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item-container {
  text-align: left;
  margin-bottom: 12px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  line-height: 26px;

  .item-left {
    color: #908d8d;
  }
  .item-status {
    border: 1px solid #39b2ee;
    text-align: center;
    color: rgb(0, 198, 255);
    border-radius: 16px;
  }
  span {
    min-width: 120px;
    display: inline-block;
  }
}
.action-btn {
  width: 94px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background-color: rgb(90, 211, 205);
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
.el-empty {
  margin-top: 140px;
}
</style>
