<template lang="pug">
  div.list(@click="goToDetail(info.id)")
    .over
      img(:src="info.previewPicUrl ? info.previewPicUrl : info.backgroundPicUrl", alt="", srcset="")
    .title {{info.introduction}}
    .class-title(v-if="!info.previewPicUrl" :style="handleTitleColor(info.backgroundPicUrl)") {{info.name}}
    .hot-logo(v-if="info.hotNum > 0") 热门
    .user-info
      img.avatar(src="@/assets/avatar_icon.png", alt="", srcset="")
      .user-name {{info.employeeName}}
      img.icon(src="@/assets/time_icon.png", alt="", srcset="")
      .time {{computedTime(info.videoLength)}}
    .price
      span(v-if="info.hasDiscount ? info.discountPrice : info.price") ￥{{info.hasDiscount ? info.discountPrice : info.price}}
      span.hasDiscount(v-if="info.hasDiscount && info.price") ￥{{ info.price }}

</template>

<script>
import {setPriceNum} from '@/utils/index'
import {pay} from '@/api/subject'

export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  name: 'SubjectListItem',
  methods: {
    computedTime(seconds) {
      // 计算小时数，通过除以3600取整（因为1小时 = 3600秒）
      const hours = Math.floor(seconds / 3600);
      // 计算剩余的秒数
      seconds %= 3600;
      // 计算分钟数，除以60取整（因为1分钟 = 60秒）
      const minutes = Math.floor(seconds / 60);
      // 最终剩余的秒数就是秒数部分
      seconds %= 60;

      // 将小时、分钟、秒数按照格式要求补零并拼接成字符串
      const hoursStr = hours.toString().padStart(2, '0');
      const minutesStr = minutes.toString().padStart(2, '0');
      const secondsStr = seconds.toString().padStart(2, '0');

      return `${hoursStr === '00' ? `${minutesStr}:${secondsStr}` : `${hoursStr}:${minutesStr}:${secondsStr}`}`;
    },
    setPriceNum,
    handleTitleColor(url) {
      const imgName = url.split('/')[url.split('/').length - 1]
      let color = ''
      switch (imgName) {
        case '1.png':
          color = 'rgba(73, 93, 173, 1)'
          break
        case '2.png':
          color = 'rgba(255, 104, 31, 1)'
          break
        case '3.png':
          color = 'rgba(92, 43, 255, 1)'
          break
        default:
          color = '#fff'
          break
      }
      return {
        color
      }
    },
    goToDetail(id) {
      this.$store.commit('ChangeSearchInput', '')
      this.$router.push({
        path: '/techVideoDetail',
        query: {id: id}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.list-items_1 {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  width: 285px;
  height: 350px;
  margin-right: 20px;
  cursor: pointer;

  .image-text_54 {
    width: 285px;
    height: 160px;
    margin-top: -1px;
    position: relative;
    overflow: hidden;
  }

  .image-box {
    max-width: 285px;
    max-height: 160px;
  }

  .text_134 {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: #f74819;
    border-radius: 8px 30px 30px 8px;
    padding-left: 8px;
    padding-right: 10px;
  }

  .text-group_19 {
    position: absolute;
    left: 15px;
    width: 165px;
    top: 48px;
    font-size: 26px;
    font-weight: 800;
    font-family: PangMenZhengDao;
    color: #ffffff;
    line-height: 30px;
  }

  .box_56 {
    width: 156px;
    margin: 12px 0 0 16px;

    .thumbnail_14 {
      width: 18px;
      height: 18px;
      margin-top: 3px;
    }

    .text_135 {
      width: 250px;
      height: 50px;
      overflow-wrap: break-word;
      color: #666666;
      font-size: 18px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      /* white-space: nowrap; */
      line-height: 25px;
      margin-left: 4px;
    }

    .image-text_56 {
      width: 68px;
      height: 25px;
      margin-top: 16px;

      .thumbnail_15 {
        width: 17px;
        height: 17px;
        margin-top: 4px;
      }

      .text-group_21 {
        width: 46px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }
    }

    .thumbnail_16 {
      width: 18px;
      height: 18px;
      margin: 3px 0 0 16px;
    }

    .text_136 {
      width: 46px;
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
      margin-left: 4px;
    }
  }

  .box_57 {
    width: 253px;
    height: 33px;
    margin: 16px 0 16px 16px;
    display: flex;

    .text_137 {
      flex: 1;
      height: 33px;
      overflow-wrap: break-word;
      color: rgba(255, 114, 76, 1);
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
    }

    .text-wrapper_35 {
      background-color: rgba(255, 114, 76, 1);
      border-radius: 15px;
      height: 30px;
      margin-top: 2px;
      width: 64px;

      .text_138 {
        width: 32px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        letter-spacing: -0.2000001072883606px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 4px 0 0 17px;
      }
    }
  }
}

.list-items_1:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.5);
}
.list {
    // width: 24%;
    // margin-right: 1%;
    // padding: 20px 30px 20px 120px;
    // padding-bottom: 20px;
    width: 285px;
    margin-right: 20px;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 21px;
    border-radius: $radius;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 329px;
    .over {
      width: 100%;

      img {
        width: 100%;
        display: block;
      }
    }

    .class-title {
      position: absolute;
      top: 48px;
      left: 12px;
      width: 165px;
      color: #fff;
      font-weight: 900;
      font-size: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
    }

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #666666;
      height: 50px;
      line-height: 25px;
      padding: 0;
      margin: 14px 16px 15px 16px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; //将对象作为弹性伸缩盒⼦模型显⽰。
      -webkit-box-orient: vertical; //从上到下垂直排列⼦元素（设置伸缩盒⼦的⼦元素排列⽅式）
      -webkit-line-clamp: 2; //这个属性不是css的规范属性，需要组合上⾯两个属性，表⽰显⽰的⾏数。此处为3⾏
      word-wrap: break-word; //允许单词内断句，⾸先会尝试挪到下⼀⾏，看看下⼀⾏的宽度够不够，不够的话就进⾏单词内的断句
    }

    .user-info {
      margin: 0 0 16px 16px;
      position: relative;
    }

    .avatar {
      width: 15px;
      height: 17px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 4px;
    }

    .icon {
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }

    .user-name {
      display: inline-block;
      vertical-align: top;
      line-height: 20px;
      font-size: 18px;
      margin-right: 20px;
      color: #666666;
    }

    .time {
      display: inline-block;
      vertical-align: top;
      color: #666666;
      // position: absolute;
      // right: 10px;
      // top: 0;
      line-height: 20px;
    }

    .price {
      margin: 0 0 16px 16px;
      position: relative;
      color: #ff724c;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 33px;
      .hasDiscount {
        text-decoration: line-through;
        font-size: 20px;
        color: #666666;
        margin-left: 10px;
      }
      .pay {
        width: 64px;
        height: 30px;
        position: absolute;
        right: 16px;
        top: 2px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        background: #ff724c;
        border-radius: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .status-tag {
        position: absolute;
        right: 16px;
        top: 2px;
      }
    }
  }
  .hot-logo {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: #f74819;
    border-radius: 8px 30px 30px 8px;
    padding-left: 10px;
    padding-right: 13px;
  }
</style>
