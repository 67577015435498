<template>
  <layoutContainer>
    <div class="enter-container">
      <div class="header-tab">
        <div class="head">实名认证</div>
        <el-button type="info" icon="el-icon-back" circle @click="$router.push('/my')"></el-button>
      </div>
      <div class="container">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" label-width="120px" size="medium" label-position="right" prop="pass">
            <el-input v-model="ruleForm.realName" autocomplete="off" class="change-password-input" :disabled="authenticated"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" label-width="120px" size="medium" label-position="right" prop="checkPass">
            <el-input v-model="ruleForm.idCardNumber" autocomplete="off" class="change-password-input" :disabled="authenticated"></el-input>
          </el-form-item>
          <div class="reset-password-btn" @click="authenticatedUser" v-if="!authenticated">开始认证</div>
          <div class="reset-password-btn-authenticated" @click="authenticatedUser" v-if="authenticated">已认证</div>
        </el-form>
      </div>
      <div class="notice">
        <img src="/img/userinfo-icon/notice.png" class="notice-icon" alt="" srcset="" />
        <div style="flex: 1; justify-content: flex-start;flex-direction: column;">
          <div class="notice-content">· 认证后将不能再修改，如需修改请 <a @click="$router.push('/connectionQuanqing')" style="cursor: pointer;color: #007aff;">联系客服</a>。</div>
          <div class="notice-content" v-if="!authenticated">· 因认证时需要使用摄像头，建议使用chrome、safari等浏览器</div>
        </div>
      </div>
    </div>
  </layoutContainer>
</template>

<script>
import {getUserAccountRealNameAuthenticated, realNameAuthenticated} from '../api/index'

export default {
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
    getUserAccountRealNameAuthenticated({validate: this.$route.query.validate === 'true'}).then(res => {
      this.authenticated = res.data.success
      if (this.authenticated) {
        this.ruleForm = {
          realName: res.data.realName,
          idCardNumber: res.data.idCardNumber,
        }
      }
    })
  },
  data() {
    return {
      ruleForm: {
        realName: '',
        idCardNumber: '',
      },
      authenticated: false
    }
  },
  methods: {
    authenticatedUser() {
      const params = this.ruleForm
      realNameAuthenticated(params).then(res => {
        if (res.code === 200) {
          window.location.href = `${res.data}&url=${encodeURIComponent(`${window.location.href}?validate=true`)}`
        } else {
          this.$message.error(res.data)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  background: #ffffff;
  padding: 30px 0;
  border-radius: 12px;
  padding-bottom: 300px;
  .head {
    width: 165px;
    height: 62px;
    background: #5ad3cd;
    border-radius: 0 30px 30px 0;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 62px;
    text-align: center;
    margin-right: 30px;
  }
  .container {
    width: 500px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .change-password-input {
    width: 300px;
  }
  .pr {
    position: relative;
  }
  .reset-password-btn {
    width: 220px;
    height: 54px;
    background: #5ad3cd;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    line-height: 54px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 40px auto 0 auto;
    cursor: pointer;
  }
  .reset-password-btn-authenticated {
    width: 220px;
    height: 54px;
    color: #22eed3;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #e9fdfb;
    border-color: #d3fcf6;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    line-height: 54px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 40px auto 0 auto;
  }

  .notice {
    width: 680px;
    padding: 16px;
    background-color: #f6f6f8;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    margin-top: 20px;
    margin: 100px auto 0 auto;
    .notice-icon {
      width: 30px;
      height: 30px;
    }
    .notice-content {
      flex: 1;
      margin-left: 16px;
      margin-top:2px;
      line-height: 24px;
      font-size: 18px;
    }
  }
  /deep/.el-form-item__label {
    padding: 0 24px 0 0;
  }
}
.header-tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
