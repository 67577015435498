<template>
  <layoutContainer>
    <div class="enter-container">
      <div class="head">{{!hasPayPassword ? '设置密码' : '重设密码'}}</div>
      <div class="container">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
          <el-form-item label="新密码" label-width="120px" size="medium" label-position="right" prop="payPassword">
            <el-input
              type="password"
              v-model="ruleForm.payPassword"
              autocomplete="off"
              class="change-password-input"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" label-width="120px" size="medium" label-position="right" prop="checkPass">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              class="change-password-input"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信验证" label-width="120px" size="medium" label-position="right" prop="verifyCode" class="pr">
            <el-input placeholder="短信验证码" v-model="ruleForm.verifyCode" class="change-password-input"></el-input>
            <div>（发送至注册手机号）</div>
            <button @click.prevent="getVerify()" class="code-btn" :disabled="!show">
              <span style="font-weight: bold" v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }}</span>
            </button>
          </el-form-item>
          <div class="reset-password-btn" @click="resetPassWord">{{!hasPayPassword ? '设置支付密码' : '重设支付密码'}} </div>
        </el-form>
      </div>
      <div class="notice">
        <img src="/img/userinfo-icon/notice.png" class="notice-icon" alt="" srcset="" />
        <div class="notice-content">在用余额支付时，会用到支付密码。</div>
      </div>
    </div>
    <sliderImgValidate
      v-if="showSliderImgValidate && params.smallImage && params.bigImage"
      :yHeight="params.yHeight"
      :xwidth.sync="params.xwidth"
      :bigImage="params.bigImage"
      :smallImage="params.smallImage"
      :showSliderImgValidate.sync="showSliderImgValidate"
      @changeImgPosition="changeImgPosition"
      @changeImgPositionEnd="changeImgPositionEnd"
      @resetPicAgain="getSliderImgValidate"
    />
  </layoutContainer>
</template>

<script>
import {resetPayPassword, slipPicSmallApi, getVerifyCodeApi, checkSlipPicSmallApi,} from '@/api/index'
import {currentLoginUser} from '@/api/project'
import md5 from 'blueimp-md5'
import sliderImgValidate from '@/components/sliderValidate'
export default {
  components: {
    sliderImgValidate
  },
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70
    })
    this.hasPayPassword = this.$route.query.hasPayPassword === 'true'
    currentLoginUser().then(res => {
      this.phoneNumber = res.data.phoneNumber
      this.params.phoneNumber = this.phoneNumber
    })
  },
  data() {
    const payPassword = (rule, value, callback) => {
      console.log('%c [ value ]-78-「resetPayPassword」', 'font-size:px;', value)
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length <= 7) {
          callback(new Error('请保证您的密码长度最少8位'))
        }
        if (/^\d+$/g.test(value) || /^[a-zA-Z]+$/g.test(value)) {
          callback(new Error('您的密码强度过低！请使用字母数字组合'))
        }
        callback()
      }
    }
    const checkPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.payPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const verifyCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入您收到的手机验证码'))
      } else if (value.length !== 6) {
        callback(new Error('请输入您收到的6位数字的手机验证码'))
      } else {
        callback()
      }
    }
    return {
      containerHeight: 0,
      phoneNumber: '',
      rules: {
        payPassword: [{validator: payPassword, trigger: 'blur'}],
        checkPass: [{validator: checkPass, trigger: 'blur'}],
        verifyCode: [{validator: verifyCode, trigger: 'blur'}]
      },
      ruleForm: {
        payPassword: '',
        checkPass: '',
        verifyCode: ''
      },
      hasPayPassword: '',
      timer: null,
      showSliderImgValidate: false,
      count: 0,
      biz: 5,
      params: {
        biz: '',
        phoneNumber: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },
      show: true
    }
  },
  methods: {
    // 修改滑块的水平坐标最终的值
    changeImgPosition(val) {
      this.params.xwidth = val
    },
    // 验证图片位置是否正确
    changeImgPositionEnd() {
      this.$nextTick(() => {
        const params = {}
        params.biz = this.biz
        params.phone = this.params.phoneNumber
        params.xwidth = this.params.xwidth || '0'
        this.showSliderImgValidate = false
        checkSlipPicSmallApi(params)
          .then(res => {
            if (res.msg == 'success') {
              this.$message.success('验证通过')
              this.params.xwidth = 0
              this.getCode()
            } else {
              // this.params.xwidth = 0
              this.getSliderImgValidate()
            }
          })
          .catch(err => {
            // this.params.xwidth = 0
            this.getSliderImgValidate()
          })
          .finally(() => {
            const {yHeight, xwidth, bigImage, smallImage} = this.$options.data().params
            this.params = {...this.params, yHeight, xwidth, bigImage, smallImage}
            console.log('%c [ this.params ]-150-「resetPayPassword」', 'font-size:px;', this.params)
          })
      })
    },
    // 获取图片验证划图图片链接
    getSliderImgValidate() {
      const params = {}
      this.showSliderImgValidate = true
      params.phone = this.params.phoneNumber
      // biz 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      params.biz = this.biz // 申请类型：1-登录密码次数超限；2-获取验证码
      slipPicSmallApi(params).then(res => {
        const params = {
          ...this.params,
          ...(res?.data || {})
        }
        this.$set(this, 'params', params)
      })
    },
    resetPassWord() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const params = {
            payPassword: md5(this.ruleForm.payPassword),
            verifyCode: this.ruleForm.verifyCode
          }
          resetPayPassword(params).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.$router.go(-1)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
      
    },
    getVerify() {
      if (this.ruleForm.payPassword !== this.ruleForm.checkPass) {
        this.$message.warning('密码不一致')
        return
      }
      this.showSliderImgValidate = true
      this.getSliderImgValidate()
    },
    getCode() {
      this.showSliderImgValidate = false
      getVerifyCodeApi({phone: this.phoneNumber, biz: this.biz})
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  background: #ffffff;
  padding: 30px 0;
  border-radius: 12px;
  padding-bottom: 300px;
  .head {
    width: 165px;
    height: 62px;
    background: #5ad3cd;
    border-radius: 0 30px 30px 0;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 62px;
    text-align: center;
  }
  .container {
    width: 500px;
    padding-top: 30px;
    margin: 0 auto;
  }
  .change-password-input {
    width: 300px;
  }
  .pr {
    position: relative;
  }
  .code-btn {
    width: 100px;
    height: 20px;
    position: absolute;
    top: 6px;
    right: -30px;
    z-index: 222;
    color: #ef8466;
    font-size: 14px;
    border: none;
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;
  }
  .reset-password-btn {
    width: 220px;
    height: 54px;
    background: #5ad3cd;
    border-radius: 40px 40px 40px 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    line-height: 54px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 40px auto 0 auto;
    cursor: pointer;
  }

  .notice {
    width: 450px;
    padding: 16px;
    background-color: #f6f6f8;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    margin-top: 20px;
    margin: 100px auto 0 auto;
    .notice-icon {
      width: 30px;
      height: 30px;
    }
    .notice-content {
      flex: 1;
      margin-left: 16px;
      margin-top: 4px;
      line-height: 24px;
      font-size: 18px;
    }
  }
  /deep/.el-form-item__label {
    padding: 0 20px 0 0;
  }
}
</style>
