<template lang="pug">
.content
  .common
    .options
      .label 
        span.icon ¥
        span(style="color: #333333; font-size: 30px; font-weight: bold;") {{info.totalAmount}}
      .name 收入金额
    .options
      .label(style="color: #333333; font-size: 30px; font-weight: bold;") {{total}}
      .name 记录条数
    .options
      .label
        span.icon  ¥
        span(style="color: #333333; font-size: 30px; font-weight: bold;") {{info.alreadyValidIncomeAmount}}
        span.num /{{info.alreadyValidIncomeTotal}}条
      .name 已生效
    .options
      .label
        span.icon  ¥
        span(style="color: #333333; font-size: 30px; font-weight: bold;") {{info.waitingValidIncomeAmount}}
        span.num /{{info.waitingValidIncomeTotal}} 条
      .ready-used 
        .name 待生效 
        el-tooltip(content="订单超过可退款期限，用户没有退款或申请中的退款时，佣金即生效" trigger="hover" placement="bottom")
          i(class="el-icon-question")
  .tabs
    el-tabs(v-model="tabActiveName" @tab-click="tabClick")
      el-tab-pane(label="全部记录" name= '-1') 
      el-tab-pane(label="创作分佣" name= '1') 
      el-tab-pane(label="代销分佣" name= '2') 
      el-tab-pane(label="分享分佣" name= '3') 
      el-tab-pane(label="导流分佣" name= '4') 
      el-tab-pane(label="推广分佣" name= '5')
    .income-search-input 
      el-select(v-model="recordType" style="margin-right: 20px;width: 180px;" v-if="isOperator")
        el-option(key="1" label="我的记录" value="1")
        el-option(key="2" label="全站记录" value="2")
      el-input(v-model="searchInputValue" placeholder="请输入内容" class="")
      el-button(type="primary" style="margin-left: 10px; background: #5AD3CD" @click="currentChange(1)") 搜索
  .list(v-for="(item, index) in info.items" :class="index === info.items.length - 1 && 'last-list'")
    .options
      .label 收入单号
      .params {{ item.incomeId }}
    .options
      .label 收入金额
      .params 
        span(style="color: red;") {{ item.incomeAmount }}
        span &nbsp;元
    .options
      .label 收入类型
      .params {{ item.incomeType }}
    .options
      .label 购买人帐号
      .params {{ item.buyUserPhoneNumber }}
    .options(v-if="item.salePlatform")
      .label 销售平台
      .params {{ item.salePlatform }}
    .options(v-if="item.platformUserId")
      .label 主播平台号
      .params {{ item.platformUserId }}
    .options(v-if="item.orderId")
      .label 订单号
      .params {{ item.orderId }}
    .options
      .label 订单金额
      .params {{ item.orderPrice }}&nbsp;元
    .options(v-if="item.discounts.length > 0")
      .label 使用优惠
      .params 
        el-tag(v-for="tag in item.discounts" :key="tag" type="success" style="margin-right:5px;") {{tag}}
    .options
      .label 下单时间
      .params {{ item.orderTime }}
    .options
      .label 收入人帐号
      .params {{ item.userPhoneNumber }}
    .options(v-if="item.incomeLevel > 1")
      .label 佣金层级
      .params {{ item.incomeLevel }}
    .options(v-if="item.precedeLevelOneIncomeUserPhoneNumber")
      .label 前序分佣人
      .params {{ item.precedeLevelOneIncomeUserPhoneNumber }} {{item.precedeLevelTwoIncomeUserPhoneNumber ? `>${item.precedeLevelTwoIncomeUserPhoneNumber}` : ''}}
    .options
      .label 状态
      .params  
        el-tag(:type="statusTagMap[item.status]" style="margin-right:5px;") {{statusTextMap[item.status]}}
    .options(v-if="item.invalidReason")
      .label 失效原因
      .params {{ item.invalidReason }}
        
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
</template>
<script>
import {getIncomeRecordListApi} from '../../api/account'
import {checkAuth} from '@/api/product'

export default {
  data() {
    return {
      total: 0,
      page: 1,
      pageSize: 10,
      tabActiveName: '-1',
      searchInputValue: '',
      recordType: '1',
      info: {},
      statusTagMap: {
        1: 'warning',
        2: 'success',
        3: 'info'
      },
      statusTextMap: {
        1: '待生效',
        2: '已生效',
        3: '已失效'
      },
      isOperator: false
    }
  },
  watch: {
    recordType() {
      this.searchInputValue = ''
    }
  },
  created() {
    this.search()
  },
  mounted() {
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
  },
  methods: {
    search() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.page,
        type: +this.tabActiveName,
        queryContent: this.searchInputValue,
        recordType: this.recordType
      }
      getIncomeRecordListApi(params).then(res => {
        this.info = res.data
        this.total = res.data.total
      })
    },
    tabClick(val) {
      this.tabActiveName = val.name
      this.page = 1
      this.search()
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: relative;
  .common {
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    .options {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-around;
      text-align: center;
    }
    .ready-used {
      display: flex;
      justify-content: center;
      align-items: center;
      .name {
        margin-right: 6px;
        color: #acacac;
      }
      .el-tooltip {
        cursor: pointer;
      }
    }
    .label {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      margin-bottom: 6px;
      vertical-align: bottom;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 20px;
      }
      .icon {
        font-size: 32px;
        margin-right: 2px;
        color: #333333;
        font-weight: bold;
      }
      .num {
        font-size: 16px;
        margin-left: 4px;
      }
    }
  }
  .list {
    padding: 48px 24px;
    border-bottom: 1px solid #f7f4f8;
    .label {
      width: 160px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 22px;
      color: #acacac;
      line-height: 26px;
      text-align: left;
    }
    .options {
      margin-bottom: 20px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .params {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      text-align: left;
    }
  }
  .last-list {
    border-bottom: none;
  }
  .name {
    font-size: 16px;
    color: #acacac;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  /deep/.el-tabs__nav-scroll {
    padding-bottom: 16px;
    .el-tabs__active-bar {
      bottom: -16px;
      background-color: #5ad3cd;
    }
    .el-tabs__item.is-active {
      color: #5ad3cd;
    }
  }
}
.tabs {
  margin-bottom: 10px;
  position: relative;
  .income-search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    right: 0;
    bottom: 30px;
    /deep/.el-input__inner {
      height: 36px;
    }
    button {
      height: 36px;
      /deep/span {
        position: relative;
        top: -1px;
      }
    }
  }
}
</style>
