<template lang="pug">
  layoutContainer
    .enter-container
      .tab-bar
        .tab(
          v-for="(item, index) in tabBarList"
          :key="index"
          @click="selectType = item.value;subTabSelect = 1"
        )
          span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
          .select-status(v-if="selectType === item.value")
      .content(:style="{'min-height': `${containerHeight}px`}")
        .content-bar(v-if="[1].includes(selectType)")
          .sub-bar(
            :class="`${subTabSelect === item.value ? 'sub-bar-select' : ''}`"
            v-for="(item, index) in tabBarList[selectType - 1].subBar"
            :key="index"
            @click="subTabSelect = item.value"
          ) {{ item.name }}
        .bar-content
          div(v-if="selectType === 1 && subTabSelect === 1")
            .price.now-income ¥ &nbsp;
              span {{ myAccountIncomeInfo.balance }}
            .btns
              .recharge(@click="selectType = 3;subTabSelect = 1") 去提现
          div(v-if="selectType === 1 && subTabSelect === 2")
            .options(style="margin-top: 60px;")
              .option-name 历史总收入
              .price ¥ &nbsp;
                span {{ myAccountIncomeInfo.historyTotalIncome }}
            .options
              .option-name 已生效收入
              .price ¥ &nbsp;
                span {{ myAccountIncomeInfo.alreadyValidIncome }}
            .options(style="margin-bottom: 60px;")
              .option-name 待生效收入
              .price ¥ &nbsp;
                span {{ myAccountIncomeInfo.waitingValidIncome }}
            .btns
              .recharge(@click="selectType = 2;subTabSelect = 1") 查看详细
          incomeRecordList(v-if="selectType === 2")
          depositIncome(v-if="selectType === 3" @changeType="selectType = 4")
          withdrawList(v-if="selectType === 4")
  </template>
<script>
import incomeRecordList from '../../components/account/incomeRecordList.vue'
import depositIncome from '../../components/account/depositIncome.vue'
import withdrawList from '../../components/account/withdrawList.vue'
import {getMyAccountIncomeApi} from '../../api/account'

export default {
  components: {
    incomeRecordList,
    depositIncome,
    withdrawList
  },
  data() {
    return {
      containerHeight: 0,
      selectType: 1,
      subTabSelect: 1,
      myAccountIncomeInfo: {},
      incomeRecordListInfo: [],
      tabBarList: [
        {
          name: '我的收入',
          value: 1,
          subBar: [
            {
              name: '现有收入',
              value: 1
            },
            {
              name: '收入统计',
              value: 2
            }
          ]
        },
        {
          name: '收入记录',
          value: 2
        },
        {
          name: '提现',
          value: 3
        },
        {
          name: '提现记录',
          value: 4
        }
      ]
    }
  },
  created() {
    getMyAccountIncomeApi().then(res => {
      this.myAccountIncomeInfo = res.data
    })
  },
  mounted() {
    this.$nextTick(() => {
      const screenHeight = document.documentElement.clientHeight
      const headerHeight = document.getElementsByClassName('headerBox')[0].offsetHeight
      const footerHeight = document.getElementsByClassName('footer-container')[0].offsetHeight
      this.containerHeight = screenHeight - headerHeight - footerHeight - 70 - 36
    })
    this.selectType = Number(this.$route.query.type) || 1
    this.subTabSelect = Number(this.$route.query.subType) || 1
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.enter-container {
  width: 1200px;
  overflow: auto;
}
.content {
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}
.sub-bar {
  padding-left: 31px;
  height: 86px;
}
.sub-bar-select {
  color: #5ad3cd;
  background-image: url('./../../assets/tabBg.png');
  background-size: 100% 100%;
}
.content-bar {
  width: 169px;
  background: #5ad3cd;
  min-height: 100%;
  box-sizing: border-box;
  padding-top: 35px;
  box-sizing: border-box;
  padding-left: 31px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  line-height: 86px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.bar-content {
  flex: 1;
  background-color: #fff;
  min-height: 100%;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-left: 56px;
  cursor: pointer;
  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.price {
  text-align: left;
  font-weight: 600;
  font-size: 32px;

  span {
    font-size: 56px;
    line-height: 78px;
  }
}
.now-income {
  margin-top: 54px;
  margin-left: 54px;
  margin-bottom: 54px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 74px;
  margin-left: 54px;
}
.recharge {
  margin-right: 56px;
  background: #ff724c;
  border-radius: 34px 34px 34px 34px;
  padding: 0 24px;
  height: 38px;
  line-height: 38px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.deposit {
  background: #ffded6;
  border-radius: 34px 34px 34px 34px;
  padding: 0 24px;
  height: 38px;
  line-height: 38px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 22px;
  color: #ff724c;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  margin-left: 54px;
  .price {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 32px;
      line-height: normal;
    }
  }
}
.option-name {
  margin-right: 20px;
  font-size: 20px;
}
</style>
