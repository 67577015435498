<template lang="pug">
.content
  .search-tab
    .search-account
      el-select(v-model="account" placeholder="请选择账户" style="margin-right: 20px;")
        el-option(
          v-for="item in accountOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id")
      el-select(v-if="isOperator" v-model="status" placeholder="请选择状态" style="width: 180px;")
        el-option(
          v-for="item in statusList"
          :key="item.value"
          :label="item.name"
          :value="item.value")
    .search-code
      span(style="width: 48px;") 搜索：
      el-select(v-model="searchTypeName" placeholder="请选择搜索类型" style="margin-right: 20px;width: 180px;")
        el-option(
          v-for="item in searchType"
          :key="item.value"
          :label="item.name"
          :value="item.value")
      el-input(v-model="searchInputValue" placeholder="请输入内容" style="width: 180px;")
      img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
  el-divider
  .content-firm(v-for="(item, index) in info" v-if="info.length > 0")
    .options
      .label 充值单号
      .params {{ item.topUpId }}
    .options
      .label 充值金额
      .params 
        span(style="color: #ff724c") {{ item.topUpAmount }}&nbsp;
        span 元
    .options
      .label 充值至账户
      .params {{ item.accountName }}
    .options
      .label 充值前余额
      .params {{ item.accountBalanceBeforeTopUp }}&nbsp;元
    .options
      .label 充值后余额
      .params {{ item.accountBalanceAfterTopUp }}&nbsp;元
    .options
      .label 充值方式
      .params {{ item.channel }}
    .options(v-if="item.sourceAccountBank")
      .label 充值来源账户
      .params {{ item.sourceAccountNumber }}
    .options(v-if="item.sourceAccountName")
      .label 来源账户名称
      .params {{ item.sourceAccountName }}
    .options(v-if="item.sourceAccountBank")
      .label 来源账户银行
      .params {{ item.sourceAccountBank }}
    .options
      .label 充值时间
      .params {{ item.rechargeTime }}
    .options(v-if="isOperator")
      .label 用户ID
      .params {{ item.userPhoneNumber }}
    .options(v-if="isOperator && item.inputUserPhoneNumber")
      .label 录入人ID
      .params {{ item.inputUserPhoneNumber }}
    .options(v-if="isOperator && item.auditUserPhoneNumber")
      .label 审核人ID
      .params {{ item.auditUserPhoneNumber }}
    .options(v-if="isOperator" style="margin-bottom: 28px;")
      .label 状态
      el-tag(:type="statusTagMap[item.status]") {{statusTextMap[item.status]}}
    .options(v-if="isOperator && (item.status === 1 || item.status === 4)" style="margin-bottom: 28px;")
      .label 拒绝原因
      el-input(v-model="item.refuseReason" placeholder="请输入内容" style="width: 500px" v-if="item.status === 1")
      .params(v-if="item.status === 4") {{ item.refuseReason }}
    .btns(v-if="item.status === 1")
      .recharge(v-if="item.showPassBtn" @click="pass(item)") 通过
      .deposit(v-if="item.showRejectBtn" @click="refuse(item)") 拒绝
    el-divider(v-if="info.length && index < info.length - 1")
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
  .empty(v-if="info.length === 0")
    img.coupon-empty(src="/img/userinfo-icon/empty.png" class="")
    span.coupon-empty-text 暂无数据
</template>
<script>
import { getAccountOptionsApi, getRechargeListApi, passApi, refuseApi } from '../../api/account';
import { checkAuth } from '@/api/product';

export default {
  data() {
    return {
      isOperator: false,
      info: [],
      total: 0,
      page: 1,
      pageSize: 10,
      accountOptions: [],
      searchType: [{
          value: 1,
          name: '充值单号',
        },
        {
          value: 2,
          name: '用户ID'
        },
        {
          value: 3,
          name: '充值至账户'
        },
        {
          value: 4,
          name: '信用代码'
        },
        {
          value: 5,
          name: '来源账户'
        },
        {
          value: 6,
          name: '来源名称'
        },
        {
          value: 7,
          name: '录入人ID'
        },
        {
          value: 8,
          name: '审核人ID'
        }
      ],
      statusList: [
        {
          value: 1,
          name: '待审核'
        },
        {
          value: 2,
          name: '待入款'
        },
        {
          value: 3,
          name: '已入款'
        },
        {
          value: 4,
          name: '已拒绝'
        },
      ],
      status: '',
      account: '',
      searchTypeName: '',
      searchInputValue: '',
      statusTagMap: {
        1: '',
        2: 'warning',
        3: 'success',
        4: 'danger',
      },
      statusTextMap: {
        1: '待审核',
        2: '待入款',
        3: '已入款',
        4: '已拒绝',
      },
    }
  },
  watch: {
    status() {
      this.page = 1
      this.search()
    },
    account() {
      this.page = 1
      this.search()
    },
    searchInputValue() {
      this.page = 1
      this.search()
    }
  },
  created() {
    // 是否是运营
    checkAuth({ and: true, authList: [1] }).then(res => {
      this.isOperator = res.code === 200; 
      this.searchType = res.code === 200 ? this.searchType : [{ value: 1, name: '充值单号',}]
    })
  },
  mounted() {
    this.getAccountOptions().then(() => {
      this.search()
    })
  },
  methods: {
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentChange(1)
    },
    getAccountOptions() {
      return getAccountOptionsApi().then(res => {
        this.accountOptions = res.data;
      })
    },
    pass(item) {
      const params = {
        recordId: item.recordId
      }
      passApi(params).then(() => {
        this.search()
      })
    },
    refuse(item) {
      if(item.refuseReason.length <= 0){
        this.$dialog.toast('请输入拒绝原因');
        return;
      }
      const params = {
        recordId: item.recordId,
        refuseReason: item.refuseReason
      }
      refuseApi(params).then(res => {
        if(res.code === 200) {
          this.search()
        }
      })
    },
    search() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.page,
        accountId: this.account,
        status: this.status,
        queryType: this.searchTypeName,
        queryContent: this.searchInputValue
      }
      getRechargeListApi(params).then(res => {
        const {records, total} = res.data;
        this.info = records;
        this.total = total;
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 55px 88px;
  flex-direction: column;
  position: relative;
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .search-account,
  .search-code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .search-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 160px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;
  }
  .options {
    margin-bottom: 32px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .el-divider--horizontal {
    margin: 48px 0;
  }
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
<style>
.el-select-dropdown__wrap {
    max-height: 474px;
}
</style>