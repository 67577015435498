<template lang="pug">
.content
  .tab-bar
    .tab(
      v-for="(item, index) in tabBarList"
      :key="index"
      @click="selectType = item.value;"
    )
      span(:style="{color: `${selectType === item.value ? '#333333' : '#acacac'}`}") {{ item.name }}
      .select-status(v-if="selectType === item.value")
  .content-container(v-if="selectType === 1")
    .options-form
      .label 适用业务
      el-select(v-model="selectedFitBusiness" multiple placeholder="请选择适用业务" style="width: 380px;")
        el-option(label="会员卡" value="会员卡")
        el-option(label="知识视频" value="知识视频")
        el-option(label="质检包" value="质检包")
    .options-form
      .label 券类型
      el-select(v-model="type" placeholder="请选择券类型" style="width: 380px;")
        el-option(label="无门槛券" value="无门槛券")
        el-option(label="满减券" value="满减券")
        el-option(label="每满减券" value="每满减券")
    .options-form(v-if="type === '无门槛券'")
      .label 券面值
      el-input(v-model="value" placeholder="请输入券面值" style="width: 380px;margin-right: 20px") 
      span(style="line-height: 40px;margin-right: 20px") 元
    .options-form(v-if="type === '满减券' || type === '每满减券'")
      .label 满减设置
      span(style="line-height: 40px;margin-right: 20px") 满
      el-input(v-model="satisfyAmount" style="width: 100px;margin-right: 20px") 
      span(style="line-height: 40px;margin-right: 20px") 元减
      el-input(v-model="subtractAmount" style="width: 100px;margin-right: 20px") 
      span(style="line-height: 40px;margin-right: 20px") 元
    .options-form
      .label 有效期
      el-input(v-model="validDays" placeholder="请输入有效期" style="width: 380px;margin-right: 20px") 
      span(style="line-height: 40px;margin-right: 20px") 天
    .options-form
      .label 适用场景
      el-select(v-model="selectedScene" multiple placeholder="请选择适用场景" style="width: 380px;")
        el-option(label="手动发券" value="手动发券")
        el-option(label="活动" value="活动")
        el-option(label="新用户" value="新用户")
        el-option(label="日常可重领" value="日常可重领")
        el-option(label="拉新" value="拉新")
    .options-form(v-if="selectedScene.includes('日常可重领')")
      .label 重领限期:
      el-input(v-model="retakeDaysLimit" placeholder="请输入重领限期" style="width: 380px;margin-right: 20px") 
      span(style="line-height: 40px;margin-right: 20px") 天
    .btns
      el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" :title="`确认${id ? '修改' : '新增'}么`" @confirm="create")
        .action(slot="reference" @click="create" style="margin-left: 130px; padding: 0px 70px; font-size: 20px; height: 56px; border-radius: 34px 34px 34px 34px; line-height: 56px") {{ id ? '修改' : '新增'}}
  .content-container(v-if="selectType === 2")
    .search-tab
      .search-account
        .label(style="margin-right: 20px;") 筛选：
        el-select(v-model="fitBusiness" placeholder="请选择适用业务" style="margin-right: 20px;")
          el-option(label="会员卡" :value="1")
          el-option(label="知识视频" :value="2")
          el-option(label="质检包" :value="3")
        el-select(v-model="couponType" placeholder="请选择劵类型" style="margin-right: 20px;")
          el-option(label="无门槛券" :value="1")
          el-option(label="满减券" :value="2")
          el-option(label="每满减券" :value="3")
        el-select(v-model="fitScene" placeholder="请选择适用场景" style="margin-right: 20px;")
          el-option(label="手动发券" :value="1")
          el-option(label="活动" :value="2")
          el-option(label="新用户" :value="3")
          el-option(label="日常可重领" :value="4")
          el-option(label="拉新" :value="5")
    el-empty.el-empty(description="暂无数据" :image-size="200" v-if="records.length === 0")
    .list(v-for="(item, index) in records" v-if="records.length > 0")
      .options
        .label 券ID
        .params {{ item.id }}
      .options
        .label 适用业务
        .params {{item.fitBusiness}}
      .options
        .label 券类型
        .params {{item.type}}
      .options(v-if="item.type === '无门槛券'")
        .label 券面值
        .params {{ item.value }} 元
      .options(v-if="item.type === '满减券' || item.type === '每满减券'")
        .label 满减设置
        .params {{item.type === '每满减券' ? '每' : ''}}满{{item.satisfyAmount}}减{{item.subtractAmount}}
      .options
        .label 有效期
        .params {{ item.validDays }}天
      .options(v-if="item.fitScenes === '日常可重领'")
        .label 重领限期
        .params {{item.retakeDaysLimit}}天
      .options
        .label 适用场景
        .params {{item.fitScenes}}
      .options
        .label 创建人
        .params {{item.createUserName}}
      .options
        .label 修改人
        .params {{item.modifyUserName}}
      .options
        .label 创建时间
        .params {{item.createTime}}
      .btns(style="margin-left: 160px")
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认修改么" @confirm="change(item)")
          .action(slot="reference")  修改
        el-popconfirm(confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认删除么" @confirm="removeCoupon(item.id)")
          .action(slot="reference")  删除
    el-pagination(
      v-if="records.length !== 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentChange"
      :total="total"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 3")
    .options-form(style="margin-bottom: 0px;")
      .label 发券设置
      .params 
        div(v-for="(item, index) in couponItems" style="margin-bottom: 28px;align-items:center;display:flex;")
          span(style="margin-right: 30px;") ID:
          el-input(v-model="couponItems[index].id" placeholder="请输入ID" style="width: 120px;margin-right: 30px;")
          span(style="margin-right: 30px;") 数量:
          el-input(v-model="couponItems[index].count" placeholder="请输入数量" style="width: 120px;margin-right: 10px;")
          i.el-icon-remove-outline(@click="couponItems.splice(index, 1);" v-if="index !== 0" style="margin-right: 30px;")
          i.el-icon-circle-plus-outline(@click="couponItems.push({id: '',count: ''});")
    .options-form
      .label 收券用户ID
      el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 4}" style="width: 380px;" placeholder="请输入内容" v-model="userIds")
    .options-form
      .label 发券原因
      el-input(type="textarea" :autosize="{ minRows: 4, maxRows: 4}" style="width: 380px;" placeholder="请输入内容" v-model="sendReason")
    .btns
      .action(@click="createCoupon" style="margin-left: 130px; padding: 0px 70px; font-size: 20px; height: 56px; border-radius: 34px 34px 34px 34px; line-height: 56px") 申请发券
    .notice(v-if="tips")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1")
        span(v-for="item in tips") · {{item}}
  .content-container(v-if="selectType === 4")
    .search-tab
      .search-account
        .label(style="margin-right: 20px;") 筛选：
        el-select(v-model="status" placeholder="请选择状态" style="margin-right: 20px;")
          el-option(label="全部" :value="-1")
          el-option(label="待审核" :value="1")
          el-option(label="已生效" :value="2")
          el-option(label="已拒绝" :value="3")
      .search-code
        .label(style="margin-right: 20px;width: 48px;") 搜索：
        el-select(v-model="queryType" placeholder="请选择搜索类型" style="margin-right: 20px;width: 200px;")
          el-option(label="发券单号" :value="1")
          el-option(label="收券用户ID" :value="2")
          el-option(label="申请人" :value="3")
        el-input(v-model="queryContent" placeholder="请输入内容" style="width: 200px;")
        img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
    .list(v-for="(item, index) in recordList" v-if="records.length > 0")
      .options
        .label 发券单号:
        .params {{ item.sendId }}
      .options()
        .label 券ID:
        div(style="display:flex;flex-direction: column;")
          .options(v-for="itm in item.couponItems")
            .label(style="width: auto;margin-right: 8px; color: #333333") ID:
            .params(style="margin-right:30px") {{ itm.id }}

            .label(style="width: auto;margin-right: 8px; color: #333333") 数量:
            .params {{ itm.count }}
      .options
        .label 收券用户ID:
        .params {{item.userIds}}
      .options
        .label 发券原因:
        .params {{ item.sendReason }}
      .options(v-if="item.status !== 2")
        .label 拒绝原因:
        .params(v-if="item.status === 1")
          el-input(v-model="item.refuseReason" placeholder="请输入内容" style="width: 500px" :disabled="item.status !== 1 && hasMarketingAuth")
        .params(v-if="item.status === 3") {{item.refuseReason}}
      .options
        .label 申请人:
        .params {{ item.applyUserName }}
      .options
        .label 审批人:
        .params {{item.auditUserName}}
      .options
        .label 创建时间:
        .params {{item.createTime}}
      .options
        .label 状态:
        .params
          el-tag(type="success" v-if="item.status === 1" style="margin-right:5px;") 待审核
          el-tag(type="success" v-if="item.status === 2" style="margin-right:5px;") 已生效
          el-tag(type="success" v-if="item.status === 3" style="margin-right:5px;") 已拒绝
      .btns(style="margin-left: 160px")
        .action(@click="passCoupon(item)" v-if="item.status === 1") 通过
        .action(@click="rejectCoupon(item)" v-if="item.status === 1") 拒绝
    el-pagination(
      v-if="recordList.length !== 0"
      class="el-pagination"
      background
      :page-size="pageSize"
      :pager-count="11"
      layout="prev, pager, next"
      @current-change="currentRecordChange"
      :total="totalRecord"
      style="text-align: right;"
    )
  .content-container(v-if="selectType === 5")
    .notice-what-for.notice(style="background-color: #fff;margin: 0;")
      img.notice-icon(src="./../../assets/notice-icon.png", alt="", srcset="")
      div(style="flex: 1; margin-left: 48px")
        div(v-for="item in isTips" style="margin-bottom: 32px")
          p {{item.question}}
          span {{item.answer}}
</template>
<script>
import {
  qaList,
  getCouponList,
  removeCoupon,
  saveCoupon,
  getRecordList,
  getTips,
  passCoupon,
  rejectCoupon,
  applySendCoupon
} from '../../api/orderAccount'
import {currentLoginUser} from '@/api/project'

export default {
  data() {
    return {
      id: '',
      tabBarList: [
        {
          name: '新增/修改券',
          value: 1
        },
        {
          name: '券列表',
          value: 2
        },
        {
          name: '手动发券',
          value: 3
        },
        {
          name: '发券记录',
          value: 4
        },
        {
          name: '什么是“代金券”？',
          value: 5
        }
      ],
      page: 1,
      pageRecord: 1,
      total: 0,
      totalRecord: 0,
      selectedFitBusiness: [],
      selectedScene: [],
      type: '',
      value: '',
      satisfyAmount: '',
      tips: [],
      subtractAmount: '',
      retakeDaysLimit: '',
      userIds: '',
      sendReason: '',
      validDays: '',
      records: [],
      pageSize: 10,
      selectType: 1,
      isTips: [],
      fitBusiness: '',
      couponItems: [
        {
          id: '',
          count: ''
        },
      ],
      couponType: '',
      fitScene: '',
      status: -1,
      queryType: '',
      queryContent: '',
      recordList: []
    }
  },
  watch: {
    fitBusiness() {
      this.currentChange(1)
    },
    couponType() {
      this.currentChange(1)
    },
    fitScene() {
      this.currentChange(1)
    },
    selectType() {
      this.page = 1
      this.search()
      this.pageRecord = 1
      this.searchRecord()
    },
    status() {
      this.pageRecord = 1
      this.searchRecord()
    },
    // queryContent() {
    //   this.pageRecord = 1
    //   this.searchRecord()
    // }
  },
  mounted() {
    qaList({position: '代金券-什么是代金券？'}).then(res => {
      this.isTips = res.data
    })
    getTips({position: '代金券-手动发券'}).then(res => {
      this.tips = res.code === 200 ? res.data : []
    })
    this.search()
    currentLoginUser().then(res => {
      this.userId = res.data.userId
      this.hasManageAuth = res.data.hasManageAuth
      this.hasMarketingAuth = res.data.hasMarketingAuth
    })
  },
  methods: {
    searchBtnClick() {
      if (this.queryType === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.queryContent === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.currentRecordChange(1)
    },
    create() {
      if (this.selectedFitBusiness.length === 0) {
        this.$message.warning('请选择适用业务')
        return
      }

      if (this.type === '') {
        this.$message.warning('请选择券类型')
        return
      }
      if (this.type === '无门槛券' && this.value === '') {
        this.$message.warning('请输入券面值')
        return
      }
      if (this.validDays === '') {
        this.$message.warning('请输入有效期')
        return
      }
      if (this.selectedScene.length === 0) {
        this.$message.warning('请选择适用场景')
        return
      }
      const params = {
        type: this.type, //产品类型 知识视频；会员
        value: this.value, //产品id列表 会员、知识视频等
        satisfyAmount: this.satisfyAmount, //客户ids
        subtractAmount: this.subtractAmount,
        promoteType: this.promoteType,
        retakeDaysLimit: this.retakeDaysLimit,
        validDays: this.validDays,
        selectedFitBusinessMember: this.selectedFitBusiness.includes('会员卡') ? 1 : 0,
        selectedFitBusinessCourse: this.selectedFitBusiness.includes('知识视频') ? 1 : 0,
        selectedFitBusinessStandard: this.selectedFitBusiness.includes('质检包') ? 1 : 0,
        selectedFitSceneManualSend: this.selectedScene.includes('手动发券') ? 1 : 0,
        selectedFitSceneActivity: this.selectedScene.includes('活动') ? 1 : 0,
        selectedFitSceneNewUser: this.selectedScene.includes('新用户') ? 1 : 0,
        selectedFitSceneDailyRetake: this.selectedScene.includes('日常可重领') ? 1 : 0,
        selectedFitScenePullNew: this.selectedScene.includes('拉新') ? 1 : 0
      }
      if (this.id) params.id = this.id
      saveCoupon(params).then(res => {
        if (res.code === 200) {
          this.$message.success(this.id ? '修改成功' : '新增成功')
          this.id = ''
          this.type = ''
          this.value = ''
          this.satisfyAmount = ''
          this.subtractAmount = ''
          this.promoteType = ''
          this.retakeDaysLimit = ''
          this.selectedFitBusiness = []
          this.validDays = ''
          this.selectedScene = []
          this.selectedFitBusiness = []
          return
        }
        this.$message.error(res.data)
      })
    },
    change(info) {
      this.selectType = 1
      this.id = info.id
      this.type = info.type
      this.value = info.value
      this.satisfyAmount = info.satisfyAmount
      this.subtractAmount = info.subtractAmount
      this.promoteType = ''
      this.retakeDaysLimit = info.retakeDaysLimit
      this.selectedFitBusiness = info.fitBusiness.split("、");
      this.validDays = info.validDays
      this.selectedScene = info.fitScenes.split("、");
    },
    checkIdDuplicates(arr) {
      const idSet = new Set()
      return arr.some(obj => {
        if (idSet.has(obj.id)) {
          return true
        }
        idSet.add(obj.id)
        return false
      })
    },
    createCoupon() {
      if (this.couponItems[0].id === '') {
        this.$message.warning('请设置发券设置')
        return
      }
      if (this.couponItems.length > 10) {
        this.$message.warning('发券设置最多十项')
        return
      }
      if (this.couponItems) {
        if (this.checkIdDuplicates(this.couponItems)) {
          this.$message.warning('发券设置中 ID 不可重复')
          return
        }
      }
      if (this.couponItems) {
        let flag = false
        for (let index = 0; index < this.couponItems.length; index++) {
          if (this.couponItems[index].count > 100) {
            flag = true
          }
        }
        if (flag) {
          this.$message.warning('发券设置中每个 ID 的发卷数量不可大于 100')
          return
        }
      }
      if (this.userIds === '') {
        this.$message.warning('请输入收券用户')
        return
      }
      if (this.userId.length > 12000) {
        this.$message.warning('请输入收券用户')
        return
      }
      if (this.sendReason === '') {
        this.$message.warning('请输入发券原因')
        return
      }
      const params = {
        couponItems: this.couponItems,
        userIds: this.userIds,
        sendReason: this.sendReason
      }
      applySendCoupon(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.couponItems = [
            {
              id: '',
              count: ''
            }
          ]
          this.userIds = ''
          this.sendReason = ''
          return
        }
        this.$message.error(res.data)
      })
    },
    search() {
      getCouponList({
        fitBusiness: this.fitBusiness,
        couponType: this.couponType,
        fitScene: this.fitScene,
        pageNum: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.total = res.data.total
        this.records = res.data.records
      })
    },
    passCoupon(item) {
      const params = {recordId: item.recordId}
      passCoupon(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentRecordChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    rejectCoupon(item) {
      const params = {recordId: item.recordId, refuseReason: item.refuseReason}
      rejectCoupon(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentRecordChange(1)
          return
        }
        this.$message.error(res.data)
      })
    },
    removeCoupon(id) {
      removeCoupon({id}).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.currentChange(1)
          return
        }
        this.$message.error(res.data)
      })
      this.currentChange(1)
    },
    currentChange(val) {
      this.page = val
      this.search()
    },
    searchRecord() {
      getRecordList({
        status: this.status,
        queryType: this.queryType,
        queryContent: this.queryContent,
        pageNum: this.pageRecord,
        pageSize: this.pageSize
      }).then(res => {
        this.totalRecord = res.data.total
        this.recordList = res.data.records
      })
    },
    currentRecordChange(val) {
      this.pageRecord = val
      this.searchRecord()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 20px 40px;
  flex-direction: column;
  position: relative;
}
.tab-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;
}
.content-container {
  box-sizing: border-box;
  padding-top: 20px;
}
.tab {
  height: 36px;
  border-radius: 0px 0px 0px 0px;
  padding-right: 56px;
  cursor: pointer;
  text-align: center;
  span {
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .select-status {
    width: 16px;
    height: 4px;
    font-size: 0;
    background: #5ad3cd;
    border-radius: 2px 2px 2px 2px;
    display: block;
    margin: 0 auto;
  }
}
.search-account,
.search-code {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.search-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list {
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 40px;
  margin-bottom: 30px;
  .options {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    text-align: center;
    margin-bottom: 16px;
    align-items: center;
    align-items: flex-start;
  }
  .label {
    width: 160px;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;

    span {
      font-weight: 500;
      font-size: 20px;
    }
    .icon {
      font-size: 14px;
      margin-right: 2px;
    }
    .num {
      font-size: 16px;
    }
  }
  .params {
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .name {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
  .tabs {
    margin-bottom: 10px;
    position: relative;
  }
}
.btns {
  display: flex;
  justify-content: flex-start;
}
.action {
  height: 40px;
  border-radius: 20px;
  background: #5ad3cd;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 20px 16px 0 0;
  padding: 0 20px;
}
.options-form {
  margin-bottom: 48px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  .label {
    width: 130px;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 40px;
    text-align: left;
  }
  .params {
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
}

.notice {
  padding: 32px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
  margin: 80px 0px;
  span {
    margin-top: 24px;
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #6D6D6D;
    line-height: 28px;
  }
  p {
    margin: 8px 0;
    color: #15b6ae;
    font-weight: bold;
    font-size: 22px
  }
}
.notice-what-for {
  display: flex;
}
.notice-icon {
  width: 40px;
  height: 40px;
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
