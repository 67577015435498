<template lang="pug">
.content
  .search-tab
    .search-account
      el-select(v-model="status" placeholder="请选择状态")
        el-option(
          v-for="item in statusList"
          :key="item.value"
          :label="item.name"
          :value="item.value")
    .search-code
      el-input(v-model="searchInputValue" placeholder="请输入内容")
      el-button(type="primary"  style="margin-left: 10px; background: #5AD3CD" @click="search") 搜索
  el-divider
  .content-firm(v-for="(item, index) in info" v-if="info.length > 0" :class="index === info.length - 1 && 'firm-content-last'")
    .options
      .label 提现单号
      .params {{ item.withdrawId }}
    .options
      .label 提现金额
      .params
        span(style="color: #ff724c") {{ item.withdrawAmount }}&nbsp;
        span 元
    .options(v-if="item.withdrawToIdentityType !== '企业账户'")
      .label 劳务所得税
      .params {{ item.freeLaborIncomeTax }}&nbsp;元
    .options
      .label 所得金额
      .params {{ item.withdrawAmountAfterTax }}&nbsp;元
    .options
      .label 提现前收入
      .params {{ item.incomeBalanceBeforeWithdraw }}&nbsp;元
    .options
      .label 提现后收入
      .params {{ item.incomeBalanceAfterWithdraw }}&nbsp;元
    .options
      .label 提现至账户
      .params {{ item.withdrawToIdentityType }}
    .options(v-if="item.withdrawToIdentityType === '个人账户'")
      .label 支付宝账号
      .params {{ item.alipayAccount }}
    .options(v-if="item.withdrawToIdentityType === '企业账户'")
      .label 公司卡账号
      .params {{ item.companyAccountNumber }}
    .options(v-if="item.withdrawToIdentityType === '企业账户'")
      .label 公司卡名称
      .params {{ item.companyAccountName }}
    .options(v-if="item.withdrawToIdentityType === '企业账户'")
      .label 公司卡开户支行
      .params {{ item.companyAccountBankSubbranch }}
    .options
      .label 提现时间
      .params {{ item.createTime }}
    .options
      .label 用户ID
      .params {{ item.userPhoneNumber }}
    .options
      .label 状态
      el-tag(:type="statusTagMap[item.status]") {{statusTextMap[item.status]}}
    .options(v-if="item.refuseReason.length > 0 && item.financeManageOperatorFlag")
      .label 原因
      el-input(v-model="item.refuseReason" v-if="item.status === 1" placeholder="请输入内容" style="width: 500px")
      .params(v-if="item.status !== 1") {{ item.refuseReason }}
    .options(style="height: 148px" v-if="item.status === 1 || (item.status !== 1 && item.fileUrls.length > 0)")
      .label 发票
      el-upload(
        style="flex: 1;"
        :file-list="item.fileUrls"
        v-if="item.status === 1"
        :headers="{ token }"
        :limit="3"
        :data="{recordId: item.recordId}"
        action="https://www.quanqingkeji.com/api/userAccount/income/withdraw/uploadFile"
        :on-exceed="handleExceed"
        :on-preview="handlePreview"
        :before-upload="beforeAvatarUpload"
        :before-remove="(res, file) => handleRemoveBefore(res, file, index)"
        :on-success="(res, file) => onUploadSuccess(res, file, index)"
        list-type="picture-card"
        class="upload-files"
        accept=".png,.jpeg,.gif,.pdf,.jpg")
        i(:class="['el-icon-plus', 'avatar-uploader-icon', 'disabled-mouse' && !item.financeManageOperatorFlag]")
      el-image(style="width: 146px;height: 146px;margin-right:10px" v-for="itm in item.fileUrls" :src="itm.url" :preview-src-list="[itm.url]" v-if="item.status !== 1")
    .options(v-if="item.financeManageOperatorFlag && item.auditUserPhoneNumber")
      .label 处理人ID
      .params {{ item.auditUserPhoneNumber }}
    .btns(v-if="item.status === 1")
      el-popconfirm(v-if="item.financeManageOperatorFlag && item.status === 1" confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认置成功么" @confirm="pass(item)")
        .recharge(slot="reference") 置成功
      el-popconfirm(v-if="item.financeManageOperatorFlag && item.status === 1" confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认置失败么" @confirm="refuse(item)")
        .deposit(slot="reference") 置失败
      el-popconfirm(v-if="item.financeManageOperatorFlag && item.status === 1" confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认置取消么" @confirm="cancel(item)")
        .deposit(slot="reference") 置取消
      el-popconfirm(v-if="(item.financeManageOperatorFlag || item.currentUserApplyFlag) && item.status === 1" confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认取消提现么" @confirm="cancelWithdraw(item)")
        .deposit(slot="reference") 取消提现
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
  .empty(v-if="info.length === 0")
    img.coupon-empty(src="/img/userinfo-icon/empty.png" class="")
    span.coupon-empty-text 暂无数据
</template>
<script>
import {
  getWithdrawRecordListApi,
  postDeleteWithdrawFileApi,
  postUserIncomeWithdrawSuccessApi,
  postUserIncomeWithdrawFailedApi,
  postUserIncomeWithdrawCancelApi,
  postUserIncomeWithdrawUserCancelApi
} from '../../api/account'
import _ from 'lodash'

export default {
  data() {
    return {
      info: [],
      total: 0,
      page: 1,
      pageSize: 10,
      statusList: [
        {
          value: -1,
          name: '全部状态'
        },
        {
          value: 1,
          name: '待处理'
        },
        {
          value: 2,
          name: '提现成功'
        },
        {
          value: 3,
          name: '提现失败'
        },
        {
          value: 4,
          name: '已取消'
        }
      ],
      status: -1,
      searchInputValue: '',
      statusTagMap: {
        1: '',
        2: 'success',
        3: 'danger',
        4: 'info'
      },
      statusTextMap: {
        1: '待处理',
        2: '提现成功',
        3: '提现失败',
        4: '已取消'
      },
      debouncePass: _(this.pass, 500)
    }
  },
  watch: {
    status() {
      this.page = 1
      this.search()
    }
  },
  created() {
    this.token = localStorage.getItem('TOKEN')
    this.search()
  },
  methods: {
    // 限制上传个数不超过3个
    handleExceed(files, fileList) {
      if (fileList && fileList.length >= 3) {
        this.$message.warning('最多只允许上传三个文件！')
      }
    },
    // 限制不超过20M
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('文件大小不可超过20M')
      }
      return isLt20M
    },
    onUploadSuccess(response, file, index) {
      if (response && response.code === 200) {
        this.info[index].fileUrls.push({
          url: file.url,
          name: file.name
        })
        this.$nextTick(() => {
          this.dealPDF()
        })
      }
    },
    // 预览
    handlePreview(file) {
      window.open(file.url)
    },
    // 移除文件之前
    handleRemoveBefore(file, fileList, index) {
      if (file.size / 1024 / 1024 > 20) {
        return
      }
      if (fileList.length === 1) {
        return this.$confirm('确认删除最后一个文件？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          this.handleRemove(file, fileList, index)
        })
      }
      this.handleRemove(file, fileList, index)
      return true
    },
    // 移除文件
    handleRemove(file, fileList, index) {
      const indexPic = this.info[index].fileUrls.findIndex(item => item.uid === file.uid)
      this.info[index].fileUrls.splice(indexPic, 1)
      const params = {
        recordId: this.info[index].recordId,
        index: index
      }
      postDeleteWithdrawFileApi(params).then(() => {
        this.$message.success('删除成功')
      })
    },
    dealPDF() {
      const liElements = document.querySelectorAll('ul.el-upload-list.el-upload-list--picture li.el-upload-list__item')
      liElements.forEach(function(liElement) {
        const aElement = liElement.querySelector('a.el-upload-list__item-name')
        if (aElement && aElement.textContent.includes('pdf')) {
          const imgElement = liElement.querySelector('img.el-upload-list__item-thumbnail')
          if (imgElement) {
            imgElement.src = 'https://img02.mockplus.cn/rp/image/2024-05-15/ebd18fc0-12a8-11ef-9b92-e511c1bffbb1.png'
          }
        }
      })
    },
    // 置成功
    pass(info) {
      const params = {
        recordId: info.recordId
      }
      postUserIncomeWithdrawSuccessApi(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.search()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 置失败
    refuse(info) {
      if (info.refuseReason.length <= 0) {
        this.$message.warning('请输入失败原因！')
        return
      }
      const params = {
        recordId: info.recordId,
        refuseReason: info.refuseReason
      }
      postUserIncomeWithdrawFailedApi(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.search()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 置取消
    cancel(info) {
      if (info.refuseReason.length <= 0) {
        this.$message.warning('请输入取消原因！')
        return
      }

      const params = {
        recordId: info.recordId,
        refuseReason: info.refuseReason
      }
      postUserIncomeWithdrawCancelApi(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.search()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 取消提现
    // TODO
    cancelWithdraw(info) {
      const params = {
        recordId: info.recordId
      }
      postUserIncomeWithdrawUserCancelApi(params).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.search()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    search() {
      const params = {
        status: this.status,
        queryContent: this.searchInputValue,
        pageNum: this.page,
        pageSize: this.pageSize
      }
      getWithdrawRecordListApi(params).then(res => {
        const {records, total} = res.data
        const newInfo = records.map(record => {
          record.fileUrls = record.fileUrls.map(item => {
            return {
              name: item.suffixName,
              url: item.url
            }
          })
          return record
        })
        this.info = newInfo
        this.total = total
        this.$nextTick(() => {
          this.dealPDF()
        })
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 55px 88px;
  flex-direction: column;
  position: relative;
  .el-divider--horizontal {
    margin: 40px 0 0 0;
  }
  .content-firm {
    padding: 40px 0 24px 0;
    border-bottom: 1px solid #f7f4f8;
  }
  .firm-content-last {
    border-bottom: none;
  }
  .btns {
    margin-top: 50px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .search-account,
  .search-code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    /deep/.el-input__inner {
      height: 36px;
    }
    button {
      height: 36px;
      /deep/span {
        position: relative;
        top: -1px;
      }
    }
  }
  .search-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 160px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;
  }
  .options {
    margin-bottom: 16px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin-right: 26px;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  /deep/.el-upload-list {
    display: inline;
    .el-upload-list__item {
      margin-right: 8px;
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  border: 2px solid #abafb6;
  border-radius: 4px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
