<template lang="pug">
.publish-content
  dialogMask(:show.sync="dialogVisible", :showClose="true")
    .title
      img(src="./../../assets/aircraft.png")
      p(v-if="type === 'publish'") 发布知识
      p(v-if="type === 'uploadVideo'") 重新发布
      p(v-if="type === 'uploadImg'") 更新小图
      p(v-if="type === 'addImage'")  上传小图
      p(v-if="type === 'change'")  修改
    div(v-if="!showPercentage" style="padding-right:100px")
      .content
        .options(v-if="type === 'publish' || type === 'uploadVideo'")
          .name.required 视频文件
          .item-publish
            div(style="position: relative;")
              el-input(v-model="videoName" placeholder="请选择知识视频")
              input.item-video-publish(type="file" :accept="accept" @change="handleFileChange" ref="inputFile")
        div(v-if="['publish', 'change'].includes(type)")
          .options
            .name.required 所属职业
            .item-publish
              el-select(v-model="form.occupation" placeholder="请选择")
                el-option(
                  v-for="item in occupationList"
                  :key="item.id"
                  :label="item.shortName"
                  :value="item.id"
                )
          .options
            .name.required 标题
            .item-publish
              el-input(v-model="form.title")
          .options
            .name.required 介绍
            .item-publish
              el-input(v-model="form.introduction" type="textarea")
          .options
            .name.no-required 内容
            .item-publish
              el-input(v-model="form.content" type="textarea")
        .options(v-if="['publish', 'addImage', 'uploadImg'].includes(type)")
          .name.no-required  预览小图
          .item-publish
            el-upload(:action="rule.uploadUrl"
              :headers="{token}"
              list-type="picture-card"
              :show-file-list="false"
              :disabled="rule.disable"
              :accept="rule.limitAccepts"
              :with-credentials="true"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-error="imgUploadError"
              :name="picture.name"
              v-loading="loading"
              element-loading-text="上传中")
              img(v-if="picture.url" :src="picture.url" style="width: 145px;height: 145px")
              i.el-icon-plus(v-else)
        div(v-if="['publish', 'change'].includes(type)")
          .options
            .name.no-required 价格
            .item-publish 审核后确定 
              el-tooltip(
                effect="dark"
                content="待审核后，根据视频体量、质量等因素确认对应级别后再得出价格"
                placement="top-start"
              )
                img.serve-desc(src="@/assets/comQuestion.png")
          .options(v-if="isOperator")
            .name.no-required 体量
            .item-publish 
              el-select(v-model="form.priceRankSizeId" placeholder="请选择")
                el-option(
                  v-for="item in courseVolumes"
                  :key="item.id"
                  :label="`${item.name}：${item.description}，${item.value} 元`"
                  :value="item.id"
                )
          .options(v-if="isOperator")
            .name.no-required 质量
            .item-publish 
              el-select(v-model="form.priceRankQualityId" placeholder="请选择")
                el-option(
                  v-for="item in courseQuality"
                  :key="item.id"
                  :label="`${item.name}：${item.description}，${item.value}`"
                  :value="item.id"
                )
          .options(v-if="isOperator")
            .name.no-required 价格
            .item-publish(style="color: red; height: 50px") 
              span(v-if="form.priceRankQualityId && form.priceRankSizeId") {{publishPrice}}元
          .options(v-if="isOperator")
            .name.no-required 制作费档位
            .item-publish 
              el-select(v-model="form.authorFeeRank" placeholder="请选择")
                el-option(
                  v-for="item in authorFeeRankList"
                  :key="item"
                  :label="item"
                  :value="item"
                )
          .options(v-if="isOperator && form.authorFeeRank !== '无' && form.authorFeeRank !== ''")
            .name.no-required 制作费金额
            .item-publish
              el-input(v-model="form.authorFeeAmount" style="margin-right: 10px;")
              span 元

          .options(v-if="isOperator")
            .name.no-required 创作分佣
            .item-publish 
              el-select(v-model="form.authorCommission" placeholder="请选择")
                el-option(
                  v-for="item in authorCommissionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              )
      .submit(@click="submitForm" v-if="['addImage', 'uploadImg', 'change'].includes(type)") 确认
      .submit(@click="submit" v-if="!['addImage', 'uploadImg', 'change'].includes(type)") 发布
    div(v-if="showPercentage")
      div(style="display: flex;justify-content: flex-start;flex-direction:row;width: 80%;margin: 50px auto 15px;align-items:center;")
        el-progress.progress(:text-inside="true" :stroke-width="26" :percentage="Math.floor(percentage)" style="flex: 1" color="#5AD3CD")
        div.del-btn(@click="del")
          img(src="/img/classdelicon.png")
          | 删除
      p(style="text-align: center;width: 544px;font-weight: 500;font-size: 18px;color: #ACACAC;line-height: 21px;margin-left:86px;") {{form.title}}
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {checkAuth} from '@/api/product'
import {publishCourse, priceRankGroup, getPublishInfo, removeUploadingVideo, publishingCourse} from '@/api/project'
import videoPublishManager from './publishVideo.js'
console.log('%c [ videoPublishManager ]-120', 'font-size:13px; background:pink; color:#bf2c9f;', videoPublishManager)

export default {
  name: 'publishDialog',
  props: ['showPublish', 'occupationList', 'courseId', 'type'],
  // uploadVideo uploadImg change addImage publish
  mounted() {
    this.token = localStorage.getItem('TOKEN')
    checkAuth({and: true, authList: [1]}).then(res => {
      this.isOperator = res.code === 200
    })
    priceRankGroup().then(res => {
      // 质量
      this.courseQuality = res.data.courseQuality
      // 体重
      this.courseVolumes = res.data.courseVolumes
    })
  },
  data() {
    return {
      dialogVisible: false,
      isOperator: false,
      accept: '.mp4,.ts,.flv,.wmv,.asf,.rm,.rmvb,.mpg,.mpeg,.3gp,.mov,.webm,.mkv,.avi',
      videoFile: null,
      applyId: '',
      form: {
        occupation: '',
        title: '',
        introduction: '',
        content: '',
        previewPicUrl: '',
        authorCommission: '', // 创作分佣
        authorFeeRank: '',
        authorFeeAmount: '',
        priceRankQualityId: '',
        priceRankSizeId: ''
      },
      token: '',
      loading: false,
      rule: {
        uploadUrl: 'https://www.quanqingkeji.com/api/file/upload',
        limitSize: 10, // MB
        limitAccepts: '.jpg,.png,.JPG,.PNG,.jpeg',
        // limitTip: '仅能上传1张图片，不超过10M，格式：png、jpg',
        acceptsErrorMsg: '上传图片只能是png,jpg,jpeg格式',
        disable: false
      },
      showPercentage: false,
      authorCommissionList: [
        {
          name: '无',
          id: '-1'
        },
        {
          name: '通用分佣方式',
          id: '0'
        },
        {
          name: '5%',
          id: '0.05'
        },
        {
          name: '10%',
          id: '0.1'
        },
        {
          name: '15%',
          id: '0.15'
        },
        {
          name: '20%',
          id: '0.2'
        },
        {
          name: '25%',
          id: '0.25'
        },
        {
          name: '30%',
          id: '0.3'
        },
        {
          name: '35%',
          id: '0.35'
        },
        {
          name: '40%',
          id: '0.4'
        },
        {
          name: '45%',
          id: '0.45'
        },
        {
          name: '50%',
          id: '0.5'
        },
        {
          name: '55%',
          id: '0.55'
        },
        {
          name: '60%',
          id: '0.6'
        },
        {
          name: '65%',
          id: '0.65'
        },
        {
          name: '70%',
          id: '0.7'
        },
        {
          name: '75%',
          id: '0.75'
        },
        {
          name: '80%',
          id: '0.8'
        },
        {
          name: '85%',
          id: '0.85'
        },
        {
          name: '90%',
          id: '0.9'
        },
        {
          name: '95%',
          id: '0.95'
        },
        {
          name: '100%',
          id: '1'
        }
      ],
      courseQuality: [],
      courseVolumes: [],
      authorFeeRankList: ['无', '少许', '中等', '大部分', '全部'],
      videoName: '',
      isDel: false,
      picture: {
        url: null,
        fid: null,
        name: 'picFile',
        smallUrl: null,
        size: 0
      },
      percentage: 0
    }
  },
  computed: {
    publishPrice() {
      const courseQualityIndex = this.courseQuality.findIndex(item => item.id === this.form.priceRankQualityId)
      const courseVolumesIndex = this.courseVolumes.findIndex(item => item.id === this.form.priceRankSizeId)
      return (
        Number(this.courseQuality[courseQualityIndex].value.toString().replace('元', '')) *
        Number(this.courseVolumes[courseVolumesIndex].value.toString().replace('元', ''))
      ).toFixed(2)
    }
  },
  watch: {
    showPublish(val) {
      this.applyId = ''
      if (val) this.dialogVisible = val
    },
    dialogVisible(val) {
      if (val && this.courseId) {
        getPublishInfo({courseId: this.courseId}).then(res => {
          const {
            previewPicUrl,
            occupation,
            title,
            introduction,
            content,
            authorCommission,
            authorFeeRank,
            authorFeeAmount,
            priceRankQualityId,
            priceRankSizeId
          } = res.data
          this.picture.url = previewPicUrl
          this.$set(this.form, 'occupation', occupation || '')
          this.$set(this.form, 'title', title || '')
          this.$set(this.form, 'introduction', introduction || '')
          this.$set(this.form, 'content', content || '')
          this.$set(this.form, 'authorCommission', authorCommission || '')
          this.$set(this.form, 'authorFeeRank', authorFeeRank || '')
          this.$set(this.form, 'authorFeeAmount', authorFeeAmount || '')
          this.$set(this.form, 'priceRankSizeId', priceRankSizeId || '')
          this.$set(this.form, 'priceRankQualityId', priceRankQualityId || '')
        })
      }
      this.percentage = videoPublishManager.getPercent()
      if (val && !this.courseId) {
        publishingCourse().then(res => {
          if (!res.data) return
          const {
            previewPicUrl,
            occupation,
            title,
            introduction,
            content,
            authorCommission,
            authorFeeRank,
            authorFeeAmount,
            priceRankQualityId,
            priceRankSizeId
          } = res.data
          this.picture.url = previewPicUrl
          this.courseId = res.data.courseId
          this.$set(this.form, 'occupation', occupation || '')
          this.$set(this.form, 'title', title || '')
          this.$set(this.form, 'introduction', introduction || '')
          this.$set(this.form, 'content', content || '')
          this.$set(this.form, 'authorCommission', authorCommission || '')
          this.$set(this.form, 'authorFeeRank', authorFeeRank || '')
          this.$set(this.form, 'authorFeeAmount', authorFeeAmount || '')
          this.$set(this.form, 'priceRankSizeId', priceRankSizeId || '')
          this.$set(this.form, 'priceRankQualityId', priceRankQualityId || '')
        })
      }
      if (!val) {
        this.$emit('update:showPublish', false)
        this.resetParams()
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    del() {
      this.dialogVisible = false
      removeUploadingVideo({applyId: this.applyId}).then(res => {
        this.isDel = true
        this.$message.success('删除成功')
      })
    },
    resetParams() {
      this.form = {
        occupation: '',
        title: '',
        introduction: '',
        content: '',
        previewPicUrl: '',
        authorCommission: '', // 创作分佣
        authorFeeRank: '',
        authorFeeAmount: '',
        priceRankQualityId: '',
        priceRankSizeId: ''
      }
      this.videoName = ''
      if (this.$refs.inputFile) {
        this.$refs.inputFile.value = ''
      }
      this.picture = {
        url: null,
        fid: null,
        name: 'picFile',
        smallUrl: null,
        size: 0
      }
    },
    verify() {
      let message = ''
      if (this.type === 'publish') {
        if (this.form.introduction === '') {
          message = '请输入介绍'
        }
        if (this.form.introduction.length > 200) {
          message = '介绍最多 200 字'
        }
        if (this.form.title === '') {
          message = '请输入标题'
        }
        if (this.form.title.length > 50) {
          message = '标题最多50汉字'
        }
        if (this.form.occupation === '') {
          message = '请输入所属职业'
        }
        if (!(this.videoFile !== null && this.videoName)) {
          message = '请选择知识视频'
        }
      } else if (this.type === 'change') {
        if (this.form.introduction === '') {
          message = '请输入介绍'
        }
        if (this.form.introduction.length > 200) {
          message = '介绍最多 200 字'
        }
        if (this.form.title === '') {
          message = '请输入标题'
        }
        if (this.form.title.length > 50) {
          message = '标题最多50汉字'
        }
        if (this.form.occupation === '') {
          message = '请输入所属职业'
        }
      } else if (this.type === 'uploadImg' || this.type === 'addImage') {
        if (this.picture.url === '') {
          message = '请选择小图'
        }
      } else if (this.type === 'uploadVideo') {
        if (!(this.videoFile !== null && this.videoName)) {
          message = '请选择知识视频'
        }
      }
      if (this.form.content.length > 5000) {
        message = '内容最多5000汉字'
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submitForm() {
      this.$confirm(
        this.type === 'addImage' ? '确定申请上传？' : this.type === 'uploadImg' ? '确定申请更新？' : '确认修改?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.submit()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    async submit() {
      let params = {}
      if (this.type === 'change') {
        if (!this.verify()) return
        params = {
          action: '修改',
          courseId: this.courseId,
          occupation: this.form.occupation, //职业id
          title: this.form.title, //标题
          introduction: this.form.introduction, //介绍
          content: this.form.content, //内容
          previewPicUrl: this.picture.url //预览小图url
        }
        if (this.form.authorCommission !== '') params.authorCommission = this.form.authorCommission
        if (this.form.authorFeeAmount !== '') params.authorFeeAmount = this.form.authorFeeAmount
        if (this.form.authorFeeRank !== '') params.authorFeeRank = this.form.authorFeeRank
        if (this.form.priceRankQualityId !== '') params.priceRankQualityId = this.form.priceRankQualityId
        if (this.form.priceRankSizeId !== '') params.priceRankSizeId = this.form.priceRankSizeId
      }
      if (this.type === 'publish') {
        if (!this.verify()) return
        params = {
          action: '发布',
          userFilePath: this.videoName,
          occupation: this.form.occupation, //职业id
          title: this.form.title, //标题
          introduction: this.form.introduction, //介绍
          content: this.form.content, //内容
          previewPicUrl: this.picture.url //预览小图url
        }
        if (this.form.authorCommission !== '') params.authorCommission = this.form.authorCommission
        if (this.form.authorFeeAmount !== '') params.authorFeeAmount = this.form.authorFeeAmount
        if (this.form.authorFeeRank !== '') params.authorFeeRank = this.form.authorFeeRank
        if (this.form.priceRankQualityId !== '') params.priceRankQualityId = this.form.priceRankQualityId
        if (this.form.priceRankSizeId !== '') params.priceRankSizeId = this.form.priceRankSizeId
      }
      if (this.type === 'uploadImg' || this.type === 'addImage') {
        if (!this.verify()) return
        params = {
          action: this.type === 'addImage' ? '上传小图' : '更新小图',
          courseId: this.courseId,
          previewPicUrl: this.picture.url //预览小图url
        }
      }
      if (this.type === 'uploadVideo') {
        if (!this.verify()) return
        params = {
          action: '重新发布',
          courseId: this.courseId,
          userFilePath: this.videoName
        }
      }
      publishCourse(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        if (this.type === 'uploadVideo' || this.type === 'publish') {
          this.showPercentage = true
          this.isDel = false
          videoPublishManager.initTvVod(
            this.videoFile[0],
            this.percentCallBack,
            this.doneCallBack,
            this.errCallBack,
            res.data.applyId
          )
          this.applyId = res.data.applyId
          return
        }
        if (this.type === 'uploadImg' || this.type === 'addImage') {
          !this.isDel && this.$message.success(this.type === 'addImage' ? '已提交上传小图申请' : '已提交更新小图申请')
        } else if (this.type === 'uploadVideo') {
          !this.isDel && this.$message.success('已提交重新发布申请')
        } else {
          !this.isDel &&
            this.$message.success(
              this.courseId ? (this.type === 'change' ? '修改成功' : '已成功提交申请') : '已成功提交申请'
            )
        }
        this.dialogVisible = false
        this.$emit('publishSuccess')
      })
      console.log('%c [ params ]-234', 'font-size:13px; background:pink; color:#bf2c9f;', params)
    },
    percentCallBack(info) {
      this.percentage = info * 100
    },
    doneCallBack(info) {
      console.log('%c [ info ]-552-「publishDialog」', 'font-size:px;', info)
      this.dialogVisible = false
      this.percentage = 0
      this.showPercentage = false
      if (this.isDel) {
        this.isDel = false
        return
      }

      this.$message.success(this.type === 'uploadVideo' ? '已提交重新发布申请' : '发布成功。请稍候点击“我发布的”查看')
      this.$emit('publishSuccess')
    },
    errCallBack(info) {
      console.log('%c [ info ]-197', 'font-size:13px; background:pink; color:#bf2c9f;', info)
    },
    beforeAvatarUpload(file) {
      let extName = file.name.substring(file.name.lastIndexOf('.'))
      if (this.rule.limitAccepts.split(',').findIndex(e => e === extName) < 0) {
        this.$message.warning(this.rule.acceptsErrorMsg)
        return false
      }
      // 图片大小检查
      if (file.size > 1024 * 1024 * 10) {
        this.$message.warning('上传图片大小不能超过' + this.rule.limitSize + 'MB')
        return false
      }
      this.loading = true
      return true
    },
    handleAvatarSuccess(res, file) {
      this.loading = false
      this.picture.url = res?.data || ''
      this.picture.smallUrl = res.smallUrl
      this.picture.name = file.name
      this.picture.size = file.size
    },
    imgUploadError(err, file, fileList) {
      this.$message.error('上传图片失败!')
      this.loading = false
    },
    handleFileChange(e) {
      const files = e.target.files
      if (!files.length) return
      // this.videoFile = files[0]
      this.videoFile = files
      this.videoName = files[0].name
      console.log('%c [ this.videoFile ]-235', 'font-size:13px; background:pink; color:#bf2c9f;', this.videoFile)
    }
  }
}
</script>

<style lang="scss" scoped>
.publish-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .no-required {
    padding-left: 15px;
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 130px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: left;
    }
    .required:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }

    .item-publish {
      line-height: 50px;
      margin-left: 171px;
      vertical-align: middle;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .tags {
        overflow: hidden;
      }
      .tag {
        width: 120px;
        height: 36px;
        margin-top: 20px;
        margin-right: 10px;
        float: left;
        border-radius: 30px;
        color: #999999;
        border: 1px solid #dcdfe6;
        text-align: center;
        line-height: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        cursor: pointer;
      }
      .select-tag {
        font-size: 16px;
        background-color: RGBA(90, 211, 205, 1);
        color: #ffffff;
      }
    }
  }
  .content {
    padding: 0 0 0 80px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 40px 0 0 233px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
.serve-desc {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 12px;
  font-size: 24px;
  cursor: pointer;
}
.item-video-publish {
  position: absolute;
  opacity: 0;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.progress {
  // margin: 30px auto;
}
.del-btn {
  height: 33px;
  border-radius: 24px 24px 24px 24px;
  border: 1px solid #acacac;
  box-sizing: border-box;
  padding: 5px 16px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 18px;
  color: #acacac;
  line-height: 21px;
  text-align: left;
  font-style: normal;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  margin-left: 32px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}

.el-link--info {
  line-height: 50px;
}

.platform_fees {
  line-height: 50px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
