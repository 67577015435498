<template>
  <div class="content my-page">
    <layoutContainer>
      <el-row :gutter="20" style="margin-top: 50px">
        <el-col :span="18" style="width: 50%; height: 307px">
          <div class="box-card main-info-box">
            <div>
              <el-row class="user">
                <el-col :span="3" class="upload-avada">
                  <el-upload
                    :on-success="uploadCallBack"
                    :headers="{token}"
                    :show-file-list="false"
                    list-type="picture-card"
                    name="picFile"
                    action="/api/file/upload"
                  >
                    <avaderImg
                      v-if="UserInfo.gender !== null"
                      :size="100"
                      :imgUrl="UserInfo.smallHeadpicUrl"
                      :sex="UserInfo.gender == 1 ? true : false"
                    ></avaderImg>
                  </el-upload>
                  <div class="loginout-button" @click="$router.push('/login')">
                    <img src="/img/tuichu.png" /> 退出登录
                  </div>
                </el-col>
                <el-col :span="21" class="user-info">
                  <el-form
                    ref="form"
                    :model="form"
                    label-width="110px"
                    size="small"
                    label-position="left"
                    :disabled="disabled"
                  >
                    <el-form-item :label="`帐        号`">
                      {{ UserInfo.phonenumber }}
                    </el-form-item>
                    <el-form-item label="昵        称">
                      <span v-if="disabled">{{ form.nickname }}</span>
                      <el-input v-else v-model="form.nickname" style="width: 215px"></el-input>
                      <!-- <i class="el-icon-edit" style="margin-left: 10px" @click="disabled = false"></i> -->
                      <img
                        src="/img/Slice 3@2x.png"
                        style="margin-left: 10px;width: 24px;height: 24px;cursor: pointer;"
                        @click="disabled = false"
                      />
                    </el-form-item>
                    <el-form-item label="性        别">
                      <span v-if="disabled">{{ form.gender === null ? '' : form.gender ? '男' : '女' }}</span>
                      <el-select v-else v-model="form.gender" placeholder="请选择活动您的性别">
                        <el-option label="男" :value="1"></el-option>
                        <el-option label="女" :value="0"></el-option>
                      </el-select>
                      <el-button
                        v-if="!disabled"
                        round
                        size="mini"
                        type="primary"
                        style="margin-left: 8px"
                        @click="sureUpdateUserInfo"
                      >
                        确定
                      </el-button>
                      <el-button
                        v-if="!disabled"
                        style="margin-left: 8px"
                        round
                        size="mini"
                        type="danger"
                        @click="disabled = true"
                      >
                        取消
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <el-form ref="form1" :model="form" label-position="left" label-width="110px" size="small">
                    <el-form-item label="密 码">
                      <span style="font-size: 14px; line-height: 14px; margin-top: 4px; margin-right: 6px"
                        >{{ hasPassword ? '******' : '无' }}
                      </span>
                      <el-button
                        type="text"
                        style="color: #5ad3cd; text-decoration: underline"
                        size="mini"
                        @click="$router.push('/login?submitType=4')"
                      >
                        {{ hasPassword ? '重设密码' : '设置密码' }}
                      </el-button>
                    </el-form-item>
                    <el-form-item label="支付密码">
                      <span style="font-size: 14px; line-height: 14px; margin-top: 4px; margin-right: 6px">
                        {{ hasPayPassword ? '******' : '无' }}
                      </span>
                      <el-button
                        type="text"
                        style="color: #5ad3cd; text-decoration: underline"
                        size="mini"
                        @click="$router.push(`/resetPayPassword?hasPayPassword=${hasPayPassword}`)"
                      >
                        {{ hasPayPassword ? '重设密码' : '设置密码' }}
                      </el-button>
                    </el-form-item>
                    <el-form-item label="认证">
                      <el-tag v-if="authenticated" @click="$router.push('/authenticationUser')" style="cursor: pointer"
                        >已认证</el-tag
                      >
                      <el-tag
                        v-if="!authenticated"
                        type="danger"
                        style="cursor: pointer"
                        @click="$router.push('/authenticationUser')"
                        >未认证</el-tag
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="6" style="height: 307px; width: 50%">
          <el-card class="box-card my-enter">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600; display: inline-flex; align-items: center; font-size: 17px"
                ><img
                  src="/img/userinfo-icon/1.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />我的入驻</span
              >
            </div>
            <div style="height: 115px">
              <div>
                <p v-if="!UserInfo.employeeId && !UserInfo.companyId && !UserInfo.teamId">
                  暂未以公司、团队或个人的身份入驻
                </p>
                <el-button
                  style="padding: 15px 0; color: #007aff"
                  v-if="!UserInfo.employeeId"
                  type="text"
                  @click="$router.push('/enter')"
                >
                  去入驻<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </div>
              <p>
                <el-button
                  style="padding: 15px 0; color: #007aff"
                  type="text"
                  v-if="UserInfo.companyId"
                  @click="
                    $router.push({
                      path: `/companyDetail/${UserInfo.companyId}`
                    })
                  "
                >
                  我的公司<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>

              <p>
                <el-button
                  style="padding: 15px 0; color: #007aff"
                  type="text"
                  v-if="UserInfo.teamId"
                  @click="
                    $router.push({
                      path: `/teams/detail/${UserInfo.teamId}`
                    })
                  "
                >
                  我的团队<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>
              <p>
                <el-button
                  style="padding: 15px 0; color: #007aff"
                  type="text"
                  v-if="UserInfo.employeeId"
                  @click="
                    $router.push({
                      path: `/personal/detail/${UserInfo.employeeId}`
                    })
                  "
                >
                  我的个人页<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600; display: inline-flex; align-items: center"
                ><img
                  src="/img/userinfo-icon/2.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />我的会员</span
              >
            </div>

            <div style="height: 200px; font-size: 16px;position: relative; color: #acacac;">
              <div style="display: flex; align-items: center; font-weight: 500">
                {{
                  vipInfo && vipInfo.hasEffected
                    ? `已是会员，有效期至 ${formatTimeFilter(vipInfo.expirationDate, 'YYYY-MM-DD')}`
                    : '暂不是会员'
                }}
                <p class="get-vip" @click="showVipPage = true">
                  {{ vipInfo && vipInfo.hasEffected ? '续费' : '开通会员' }}
                </p>
              </div>
              <i class="el-icon-warning-outline tanhao">
                <span style="padding-left: 4px">适用于公司、产品、服务的官网电话等信息解锁</span>
              </i>
            </div>

            <!--  -->
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600; display: inline-flex; align-items: center"
                ><img
                  src="/img/userinfo-icon/5.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />代金劵</span
              >
            </div>
            <div
              style="height: 200px; font-size: 13px; display: flex; flex-direction: column; justify-content: center; align-items: center"
            >
              <div class="coupon coupon-bg-disabled" v-if="myCouponList.length > 0">
                <div class="coupon-price">
                  <span class="price-icon">¥</span>
                  <span class="price-value">{{
                    myCouponList[0].type === '无门槛券' ? myCouponList[0].value : myCouponList[0].subtractAmount
                  }}</span>
                </div>
                <div class="coupon-info">
                  <span v-if="myCouponList[0].type === '无门槛券'">无门槛</span>
                  <span v-if="myCouponList[0].type !== '无门槛券'"
                    >{{ myCouponList[0].type === '每满减券' ? '每' : '' }}满&nbsp;{{
                      myCouponList[0].satisfyAmount
                    }}&nbsp;可用</span
                  >
                  <br />
                  <div style="height: 46px; margin-top: 3px">
                    <el-tooltip class="item" effect="dark" :content="myCouponList[0].fitBusiness" placement="top-start">
                      <span>{{
                        myCouponList[0].fitBusiness.length > 5
                          ? `${myCouponList[0].fitBusiness.substring(0, 5)}..等`
                          : myCouponList[0].fitBusiness
                      }}</span>
                    </el-tooltip>
                    <span>&nbsp;适用</span>
                  </div>
                  <span class="coupon-time">有效期至{{ myCouponList[0].expireTime }}</span>
                </div>
              </div>
              <div class="coupon" v-if="myCouponList.length === 0">
                <div class="empty" style="margin-left: 150px">
                  <img src="/img/userinfo-icon/empty.png" class="coupon-empty" alt="" srcset="" />
                  <span class="coupon-empty-text">暂无代金券</span>
                </div>
              </div>
              <div class="coupon-button">
                <div
                  class="check-all"
                  @click="
                    showCouponDialog = true
                    checkCouponType = 'checkMore'
                  "
                >
                  查看更多({{ myAllCouponList.length }})
                </div>
                <div
                  class="get-coupon"
                  @click="
                    showCouponDialog = true
                    checkCouponType = 'getCoupon'
                  "
                >
                  领劵
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" class="box-card-package">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600; display: inline-flex; align-items: center"
                ><img
                  src="/img/userinfo-icon/3.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />我的钱包</span
              >
            </div>
            <div class="my-package">
              <div class="my-package-balance">
                <div class="my-package-price">
                  <span style="font-size: 22px; margin-right: 2px">¥ </span>{{ pricePackage.balance || '0.00' }}
                </div>
                <div class="my-package-price-text">余额</div>
                <div class="my-package-btns">
                  <div
                    class="my-package-btn"
                    style="background: #ff724c; color: #fff"
                    @click="$router.push({path: `/account?type=2`})"
                  >
                    充值
                  </div>
                  <div class="my-package-btn" @click="$router.push({path: `/account?type=4`})">提现</div>
                  <div class="my-package-btn" @click="$router.push({path: `/account?type=1`})">账户</div>
                </div>
              </div>
              <div class="my-package-income">
                <div class="my-package-price">
                  <span style="font-size: 22px; margin-right: 2px">¥ </span>{{ pricePackage.incomeAmount }}
                </div>
                <div class="my-package-price-text">现有收入</div>
                <div class="my-package-btns">
                  <div class="my-package-btn" @click="$router.push({path: `/income?type=3`})">提现</div>
                </div>
              </div>
              <div class="my-package-all-income">
                <div class="my-package-price">
                  <span style="font-size: 22px; margin-right: 2px">¥ </span>{{ pricePackage.totalIncomeAmount }}
                </div>
                <div class="my-package-price-text">总收入</div>
                <div class="my-package-btns">
                  <div class="my-package-btn" @click="$router.push({path: `/income?type=1&subType=2`})">查看详情</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="12" class="box-card-make-money">
          <el-card class="box-card box-card-">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600; display: inline-flex; align-items: center"
                ><img
                  src="/img/userinfo-icon/6.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />一起赚佣金</span
              >
            </div>
            <div style="height: 96px">
              <div class="buttons-make-money">
                <div class="make-btn" @click="$router.push('/marketingCenter?type=1')">代销</div>
                <div class="make-btn" @click="$router.push('/marketingCenter?type=2')">分享</div>
                <div class="make-btn" @click="$router.push('/marketingCenter?type=3')">导流</div>
              </div>
              <div class="buttons-make-money">
                <div class="make-btn" style="margin-left: 62px" @click="$router.push('/marketingCenter?type=4')">
                  推广
                </div>
                <div class="make-btn" style="margin-right: 62px" @click="$router.push('/marketingCenter?type=5')">
                  引荐
                </div>
              </div>
              <div class="make-money-introduce">
                <img src="/img/userinfo-icon/7.png" class="make-money-introduce-icon" alt="" />
                <span class="make-money-introduce-text">空余时间动动手指，轻松赚佣可达20%</span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-card class="box-card none-hr">
            <div slot="header" class="clearfix">
              <span style="font-weight: 600"
                ><img
                  src="/img/userinfo-icon/4.png"
                  alt=""
                  style="height: 32px; margin-right: 4px; vertical-align: middle"
                />入团邀请</span
              >
            </div>
            <el-button v-if="!UserInfo.employeeId" type="text" @click="$router.push('/enter')">
              个人入驻后，即可被团队邀请，去入驻<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
            <div v-else-if="inviteList.length" v-for="(item, i) in inviteList" :key="i">
              <el-row type="flex" style="padding: 10px 0">
                <el-col :span="2">
                  <span @click="$router.push({path: `/teams/detail/${item.team.id}`})" class="hoverstyle">
                    {{ item.team.name }}
                  </span>
                </el-col>
                <el-col :span="2" :offset="2">
                  {{ item.team.memberNum }}人 | <i class="el-icon-location-information"></i
                  >{{ item.team.provinceId | AreaListFilter }}
                </el-col>
                <el-col :span="5" :offset="6"> {{ item.inviteTime | formatTimeFilter }}邀请</el-col>
                <el-col :span="5" :offset="2">
                  <el-button @click="employeeInviteAgree(item.team)" type="primary">同意</el-button>
                  <el-button @click="employeeInviteReject(item.team)" type="danger">拒绝</el-button>
                  <el-button @click="employeeInviteDelete(item.team)" type="info">删除</el-button>
                </el-col>
              </el-row>
            </div>
            <el-button v-else type="text" @click="$router.push('/teams')" style="color: #007aff">
              暂无团队邀请，去看看<i class="el-icon-arrow-right el-icon--right"></i>
            </el-button>
          </el-card>
        </el-col>
      </el-row>
    </layoutContainer>
    <el-dialog :visible.sync="DialogVisible" width="920px">
      <marketing :submitType="2" :visible.sync="DialogVisible"></marketing>
    </el-dialog>
    <vip :showVipPage="showVipPage" @dialogClose="dialogClose"></vip>
    <el-dialog :visible.sync="showCouponDialog" width="920px">
      <!-- <marketing :submitType="2" :visible.sync="DialogVisible"></marketing> -->
      <div slot="title" class="coupon-title">
        <img src="/img/userinfo-icon/couponIcon.png" class="coupon-icon" alt="" srcset="" />
        <span>{{ checkCouponType === 'checkMore' ? '我的代金券' : '领券' }}</span>
      </div>
      <div style="overflow: auto;height: 590px;">
        <div
          v-for="(item, idx) in checkCouponType === 'checkMore' ? myAllCouponList : availableCouponList"
          :class="
            `coupon ${
              checkCouponType === 'checkMore'
                ? !(item.status === 2 || item.status === 1)
                  ? `${checkCouponType === 'checkMore' ? 'coupon-bg' : 'coupon-bg need-pointer'}`
                  : 'coupon--geted-bg'
                : item.status === 1
                ? `${checkCouponType === 'checkMore' ? 'coupon-bg' : 'coupon-bg need-pointer'}`
                : 'coupon--geted-bg'
            }`
          "
          :key="idx"
          @click="e => handleGetCoupon(e, item)"
          style="float: left; margin-left: 27px"
        >
          <!--  -->

          <div class="coupon-price">
            <span class="price-icon">¥</span>
            <span class="price-value">{{ item.type === '无门槛券' ? item.value : item.subtractAmount }}</span>
          </div>
          <div class="coupon-info">
            <span v-if="item.type === '无门槛券'">无门槛</span>
            <span v-if="item.type !== '无门槛券'"
              >{{ item.type === '每满减券' ? '每' : '' }}满&nbsp;{{ item.satisfyAmount }}&nbsp;可用</span
            >
            <br />
            <div style="height: 46px; margin-top: 3px">
              <el-tooltip class="item" effect="dark" :content="item.fitBusiness" placement="top-start">
                <span
                  >{{
                    item.fitBusiness.length > 5 ? `${item.fitBusiness.substring(0, 5)}..等` : item.fitBusiness
                  }}适用</span
                >
              </el-tooltip>
              <!-- <span>{{ item.fitBusiness }}</span> -->
            </div>
            <span v-if="checkCouponType === 'checkMore'" class="coupon-time">{{
              item.status === 1 ? '已使用' : item.status === 2 ? '已过期' : `有效期至${item.expireTime}`
            }}</span>
            <span v-if="checkCouponType !== 'checkMore' && item.dailyRetakeTime" class="coupon-time"
              >{{ item.dailyRetakeTime }}可再领</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import marketing from '@/components/marketing'

import {
  getEmployeeInviteListApi,
  employeeInviteAgreeApi,
  employeeInviteRejectApi,
  employeeInviteDeleteApi,
  userModifyInfoApi,
  getMyWalletApi,
  getMyCouponListApi,
  getMyAllCouponListApi,
  getAvailableCouponListApi,
  handleGetCouponApi
} from '../api/personal'
import {currentLoginUser} from '@/api/project'
import {getUserInfoApi} from '@/api/index'

import {getUserAccountRealNameAuthenticated} from '../api/index'
import {checkVip} from '../api/product'
import {formatTimeFilter} from './../utils/index'
import {mapState, mapMutations, mapActions} from 'vuex'
import vip from '../components/vip/index.vue'

export default {
  components: {marketing, vip},
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList', 'EmployeeStationList'])
  },
  data() {
    return {
      showVipPage: false,
      DialogVisible: false,
      showCouponDialog: false,
      disabled: true,
      checkCouponType: '',
      token: '',
      form: {
        nickname: '',
        gender: '',
        logoPicUrl: '',
        date2: ''
      },
      vipInfo: {},
      pricePackage: {},
      myCouponList: [], // 代金券的列表
      myAllCouponList: [], // 查看更多的列表
      availableCouponList: [], // 领券的列表
      inviteList: [],
      authenticated: false,
      hasPassword: false,
      hasPayPassword: false
    }
  },
  created() {
    this.token = localStorage.getItem('TOKEN')
    this.getEmployeeInviteList()
    this.form.nickname = this.UserInfo.nickname
    this.form.gender = this.UserInfo.gender
    this.form.logoPicUrl = this.UserInfo.smallHeadpicUrl

    this.ChangeUserInfoActions()
    checkVip().then(res => {
      this.vipInfo = res.data
    })
    getMyWalletApi().then(res => {
      this.pricePackage = res.data
    })
    // 我的代金券
    this.getMyCouponList()
    // 我的所有代金券
    this.getMyAllCouponList()
    // 我可以领取的代金券
    this.getAvailableCouponList()
    // 查询认证
    getUserAccountRealNameAuthenticated({validate: false}).then(res => {
      this.authenticated = res.data.success
    })
    currentLoginUser().then(res => {
      const phoneNumber = res.data.phoneNumber
      getUserInfoApi({phone: phoneNumber}).then(res => {
        this.hasPassword = res.data.hasPassword
        this.hasPayPassword = res.data.hasPayPassword
      })
    })
  },
  methods: {
    ...mapMutations(['ChangeUserInfo']),
    ...mapActions(['ChangeUserInfoActions']),
    dialogClose(value) {
      this.showVipPage = false
      if (value) {
        checkVip().then(res => {
          this.vipInfo = res.data
        })
      }
    },
    getTimeOver(time) {
      console.log('%c [ time ]-540-「my」', 'font-size:px;', time)
      if (!time) return false
      let parts = time.split('.')
      let newDateStr = parts.join('/')
      console.log(
        '%c [ new Date().getTime() < new Date(newDateStr).getTime() ]-544-「my」',
        'font-size:px;',
        new Date().getTime() < new Date(newDateStr).getTime()
      )
      return new Date().getTime() < new Date(newDateStr).getTime()
    },
    sureUpdateUserInfo() {
      console.log('submit!')
      this.userModifyInfo()
    },
    formatTimeFilter,
    userModifyInfo() {
      userModifyInfoApi({
        gender: this.form.gender,
        nikeName: this.form.nickname,
        phone: this.UserInfo.phonenumber,
        logoPicUrl: this.form.logoPicUrl
      }).then(res => {
        //TODO: 重新获取个人信息
        this.disabled = true
        this.ChangeUserInfoActions()
      })
    },
    getEmployeeInviteList() {
      getEmployeeInviteListApi({employeeId: this.UserInfo.id})
        .then(res => {
          this.inviteList = [...(res?.data || [])]
        })
        .catch(err => {})
    },
    employeeInviteAgree(item) {
      this.$confirm('确认同意加入此团队吗？').then(() => {
        employeeInviteAgreeApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已成功加入团队！')
              this.ChangeUserInfoActions()
            } else {
              res.data && this.$message(res.data)
            }

            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    employeeInviteReject(item) {
      this.$confirm('确认拒绝加入此团队吗？').then(() => {
        employeeInviteRejectApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已拒绝此团队的邀请！')
            } else {
              res.data && this.$message(res.data)
            }
            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    employeeInviteDelete(item) {
      this.$confirm('确认删除此团队的邀请吗？').then(() => {
        employeeInviteDeleteApi({teamId: item.id, employeeId: this.UserInfo.id})
          .then(res => {
            if (!res.data) {
              this.$message('您已删除此团队的邀请！')
            } else {
              res.data && this.$message(res.data)
            }
            this.getEmployeeInviteList()
          })
          .catch(err => {
            this.getEmployeeInviteList()
          })
      })
    },
    uploadCallBack(res) {
      console.log('res', res)
      if (res.data) {
        this.form.logoPicUrl = res.data
        this.userModifyInfo()
      }
    },
    deletePic() {
      // this.$emit('uploadCallBack', i)
    },
    // 领券
    handleGetCoupon(e, item) {
      if (this.checkCouponType === 'checkMore' || item.status === 2) {
        return
      }
      const params = {
        couponId: item.id
      }
      handleGetCouponApi(params).then(res => {
        if (res.code === 200) {
          this.$dialog.toast('领取成功')
          this.getMyCouponList()
          this.getMyAllCouponList()
          this.getAvailableCouponList()
          this.showCouponDialog = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 我的代金券
    getMyCouponList() {
      getMyCouponListApi().then(res => {
        this.myCouponList = res.data.records
      })
    },
    // 我的所有代金券
    getMyAllCouponList() {
      getMyAllCouponListApi().then(res => {
        this.myAllCouponList = res.data
        console.log('%c [ this.myAllCouponList ]-659-「my」', 'font-size:px;', this.myAllCouponList)
      })
    },
    // 我可以领取的代金券
    getAvailableCouponList() {
      getAvailableCouponListApi().then(res => {
        this.availableCouponList = res.data
      })
    }
  }
  // TODO: vuex判断是否入驻状态
}
</script>

<style lang="scss" scoped>
.my-page {
  /deep/ .el-card__header {
    padding: 20px 32px 0 32px !important;
    border: none;
  }
}

.my-enter {
  .el-card__header {
    border: none;
    position: relative;
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  .el-dialog__body {
    padding: 0 20px;
    overflow: scroll;
    height: 600px;
  }

  .el-dialog__header {
    padding: 20px 20px 0;
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  margin: 10px;
}

/* 选项卡*/
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.clearfix {
  border: none;
}

.box-card {
  // width: 480px;
  height: 100%;
  border-radius: 8px;

  .el-card__header {
    border: none;
    padding: 12px 32px !important;
  }

  .el-card__body {
    padding: 20px 32px;
  }
}

/** */

.loginout-button {
  width: 144px;
  margin: 12px 0 0 32px !important;
  text-align: center;
  height: 40px;
  border-radius: 28px 28px 28px 28px;
  border: 1px solid #acacac;
  font-family: PingFang HK, PingFang HK;
  font-weight: 400;
  font-size: 20px;
  color: #acacac;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  img {
    height: 22px;
  }
}

.upload-avada {
  width: 176px !important;

  /deep/ .el-upload--picture-card {
    border: none !important;
    overflow: visible;
    width: 107px;
    height: 107px;
  }

  > div {
    margin: 32px 0 0 52px;

    // z-index: 999999;
    .el-upload--picture-card {
      background: none;
      border: none;
      width: 107px;
      height: 107px;

      > .avader-box {
        width: 107px !important;
        height: 107px !important;
        margin-top: 0;
      }
    }
  }
}

.main-info-box {
  background: #fff;
}

.conten .el-form-item__label {
  white-space: pre;
  font-family: PingFang HK, PingFang HK;
  font-weight: 400;
  font-size: 22px;
  color: #acacac;
  line-height: 28px;
  height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.content {
  /deep/.el-form-item__label {
    color: #acacac;
    font-size: 22px;
    padding-right: 20px;
  }
  /deep/ .el-form-item__content {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
  }
}

.recommend-button {
  width: 140px;
  height: 24px;
  line-height: 24px;
  background: #5ad3cd;
  border-radius: 2px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  border: none;
  outline: none;
}

.none-hr {
  .el-card__header {
    border: none;
  }
}

.get-vip {
  height: 30px;
  background: linear-gradient(360deg, #ff563f 0%, #ffa13f 100%);
  box-shadow: 0px 2px 4px 0px rgba(255, 146, 102, 0.5);
  border-radius: 16px;
  padding: 0 16px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 30px;
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  cursor: pointer;
}

.user-info {
  flex: 1;
  width: auto !important;
  padding-left: 22px;
  padding-top: 34px;

  .el-form-item__content {
    display: flex;
    align-items: center;
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.coupon {
  width: 400px;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 23px;
}

.coupon-bg {
  background-image: url(/img/userinfo-icon/coupon-bg.png);
  background-size: 100% 100%;
}
.coupon-bg-disabled {
  background-image: url(/img/userinfo-icon/coupon-bg.png);
  background-size: 100% 100%;
}

.need-pointer {
  cursor: pointer;
}

.coupon--geted-bg {
  background-image: url(/img/userinfo-icon/coupon--geted-bg.png);
  background-size: 100% 100%;
}

.coupon-empty {
  width: 96px;
  height: 96px;
  margin-bottom: 9px;
  display: block;
}

.coupon-price {
  width: 124px;
  box-sizing: border-box;
  text-align: center;

  .price-icon {
    font-family: DINPro, DINPro;
    font-weight: bolder;
    font-size: 22px;
    color: #ff724c;
    line-height: 38px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: inline-block;
    margin-right: 6px;
  }

  .price-value {
    font-family: DINPro, DINPro;
    font-weight: bolder;
    font-size: 46px;
    color: #ff724c;
    line-height: 38px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.coupon-info {
  flex: 1;
  padding-left: 32px;

  span {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 22px;
    color: #b1864c;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  .coupon-time {
    font-family: PingFang HK, PingFang HK;
    font-weight: 400;
    font-size: 18px;
    color: #c49d6a;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}

.coupon-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .check-all,
  .get-coupon {
    height: 28px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #ff724c;
    line-height: 30px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-right: 36px;
    border-bottom: 1px #ff724c solid;
    cursor: pointer;
  }

  .get-coupon {
    margin-right: 0px;
  }
}

.box-card-make-money {
  width: 482px;
  height: 258px;
}

.buttons-make-money {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
}

.make-btn {
  width: 94px;
  height: 36px;
  line-height: 36px;
  background: #5ad3cd;
  border-radius: 28px 28px 28px 28px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}

.make-money-introduce {
  margin-top: 23px;
  width: 100%;
  height: 36px;
  background: rgba(254, 206, 10, 0.12);
  border-radius: 34px 34px 34px 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.make-money-introduce-icon {
  margin-left: 16px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.make-money-introduce-text {
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 20px;
  color: #ff724c;
  line-height: 23px;
  text-align: center;
}

.box-card-package {
  width: 738px;
  height: 258px;
}

.coupon-empty-text {
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 20px;
  color: #acacac;
  line-height: 23px;
  font-style: normal;
  text-transform: none;
}

.my-package {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-package-balance {
  width: 288px;
  height: 137px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
}

.my-package-income {
  width: 160px;
  height: 137px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
}

.my-package-all-income {
  width: 196px;
  height: 137px;
  background: #f8f8f8;
  border-radius: 12px 12px 12px 12px;
}

.my-package-price {
  margin-bottom: 2px;
  font-family: DINPro, DINPro;
  font-weight: bold;
  font-size: 35px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-top: 8px;
}

.my-package-price-text {
  margin-bottom: 6px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 20px;
  color: #d1d1d1;
  line-height: 28px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.my-package-btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 0 14px;
}

.my-package-btn {
  height: 33px;
  border-radius: 34px 34px 34px 34px;
  border: 1px solid #ff724c;
  padding: 0 22px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 600;
  font-size: 18px;
  color: #ff724c;
  line-height: 31px;
  text-align: center;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}

.coupon-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.coupon-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tanhao {
  color: #d3cbcb;
  font-weight: 500;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>

<style lang="scss">
.my-page {
  .el-form-item--small.el-form-item {
    margin-bottom: 8px;
  }
}
.hoverstyle:hover {
  color: #5ad3cd !important;
}

/* .el-card {
  border: none;
  overflow: visible;
}
.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
} */
</style>
